import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { crmData } from '../../../../data/customer/CRM';
import Icon from '../../../HOC/Icon';
import Modal1 from '../../../DIsplay/Modal/Modal1';
import Button1 from '../../../form/Button1';
import TextInput from '../../../form/TextInput';
import { FileUploader } from 'react-drag-drop-files';
import updateProfile from '../../../../controllers/user/updateProfile';
import { setUser } from '../../../../redux/reducers/userSlice';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import CreateFlightOrder from '../../../../pages/Orders/Flight/CreateFlightOrder';
import { clone } from '../../../../features/utils/objClone';

const ModalContext = createContext(null);

var cancelTokenSource = null; 

export default function HomeCRM() {
    const {user} = useSelector(state => state.user.userData);
    let {hero,destination,destinations,banners,quickInfo} = clone(user?.detail?.crmData || crmData);
    // dbData.sections = dbData?.sections?.map(obj => ({...obj,image: undefined,savedImage: obj?.image}))
    const defData = clone({hero,destinations,banners,quickInfo});
    const [data,setData] = useState((defData))
    const [modalOpen,setModalOpen] = useState({hero: false,heroImage: false,destination: false})
    const [loading,setLoading] = useState({submit: false})
    const dispatch = useDispatch();

    async function handleUpload(ev,newData) {
        ev?.preventDefault();
        // return console.log('payload: ',data)
        // if(bg?.file) {
        //     const formData = new FormData();
        //     formData.append('crmData.aboutUs.backgroundImage',bg?.file);
        //     const res = await updateProfile(formData)
        //     if(res.return) {
        //         enqueueSnackbar('Image Uploaded',{variant: 'success'})
        //     } else enqueueSnackbar(res.msg,{variant: 'error'})
        // }

        if(cancelTokenSource)
            cancelTokenSource.cancel('Canceled for another request')
        cancelTokenSource = axios.CancelToken.source()
        
        setLoading({...loading,submit: true});
        const res = await updateProfile({crmData: {...(user?.detail?.crmData || {}),...(newData || data || {})}},cancelTokenSource?.token)
        setLoading({...loading,submit: false});
        console.log(res)
        if(res.return) {
            enqueueSnackbar('Page Updated',{variant: 'success'})
            console.log(res.data)
            dispatch(setUser(res.data))
        } else enqueueSnackbar(res.msg,{variant: 'error'})

    }

    
    const EditModal = ({children,title,data:defData,...props}) => {
        const [value,setValue] = useState(defData || '')

        function handleSubmit() {
            const updData = {...data,...value};
            setData(updData)
            handleUpload(null,updData)
            props?.setOpen && props?.setOpen(false);
        }
        
        return (
            <Modal1 {...props}>
                <div className='p-5 flex flex-col gap-4'>
                    <div className='flex gap-4 justify-between py-4'>
                        <b>{title}</b>
                        <Icon icon='mdi:close' onClick={() => props?.setOpen()} />
                    </div>
                    <ModalContext.Provider value={{value,setValue}}>
                        {children}
                    </ModalContext.Provider>
                    <div className='flex gap-4'>
                        <div>
                            <Button1 variant='text' onClick={() => props?.setOpen()}>Close</Button1>
                        </div>
                        <Button1 onClick={() => handleSubmit()}>Save</Button1>
                    </div>
                </div>
            </Modal1>
        )
    }

    console.log(data)

    function handleRemoveHeroImage(i) {
        try {
            const temp = clone(data);
            console.log(temp);
            temp.hero.images = temp.hero?.images.filter((_,ind) => ind !== i)
            setData(temp);
            handleUpload(null,temp)
        } catch(ex) {console.log(ex)}
    }

    function handleRemoveBanner(i) {
        try {
            const temp = clone(data);
            temp.banners = temp?.banners?.filter((_,ind) => ind !== i)
            setData(temp);
            handleUpload(null,temp)
        } catch(ex) {}
    }

    function handleRemoveQuickInfo(i) {
        try {
            const temp = clone(data);
            temp.quickInfo = temp?.quickInfo?.filter((_,ind) => ind !== i)
            setData(temp);
            handleUpload(null,temp)
        } catch(ex) {}
    }

    function handleRemoveDestination(i) {
        try {
            const temp = clone(data);
            temp.destinations = temp?.destinations?.filter((_,ind) => ind != i)
            setData(temp);
            handleUpload(null,temp)
        } catch(ex) {}
    }

    function isUrl(val) {
        if((val || '')?.startsWith('http'))
            return true;
        return false;
    }

    function getBase64(val) {
        try {
            const ext = val?.split('.')?.at(-1)
            const metaData = 'data:image/'+ext+';base64,'
            return metaData+val;
        } catch(ex) {}
        return 'data:image/jpeg;base64,' + val
    } 

    const sections = [
        {title: 'Hero Section',
            section: [
                {
                    label: 'Hero Text',
                    body: data?.hero?.text,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,hero: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Change <Icon icon='tabler:edit' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.hero} setOpen={() => setModalOpen({...modalOpen,hero: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};
                                    console.log('obj: ',obj)
                                    return (
                                        <div className=''>
                                            <TextInput label={'Hero Text'} value={value?.hero?.text || ''} onChange={(ev) => setValue({hero: {images: data?.hero?.images,text: ev.target.value}})} />
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                },
                {
                    label: 'Hero Image',
                    body: <ImageListComponent handleDelete={(i) => handleRemoveHeroImage(i)} data={data?.hero?.images} />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,heroImage: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Add <Icon icon='material-symbols:add' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.heroImage} setOpen={() => setModalOpen({...modalOpen,heroImage: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};

                                    async function handleBG(file) {
                                        // setBG({file,url: window.URL.createObjectURL(file)})
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(file);
                                        fileReader.onload = () => {
                                            const base64Data = fileReader.result.split(',')[1];
                                            setValue({hero: {images: [...(data?.hero.images || []),base64Data],text: data?.hero?.text}})
                                        }
                                    }
                                
                                    return (
                                        <div className=''>
                                            <FileUploader
                                                handleChange={handleBG} name={'files'} types={["JPG","JPEG","PNG","GIF"]}
                                            >
                                                <div className='border rounded-md p-4 flex gap-4 items-center text-blue-700'>
                                                    <div className='w-20 h-20 bg-primary/30 flex items-center justify-center rounded-md'>
                                                        {value?.hero?.images?.length ?
                                                            <img src={'data:image/jpeg;base64,'+(value?.hero?.images?.at(-1))} className='w-full h-full object-cover' />
                                                        :<Icon icon='ph:image-thin' className='text-primary/50' />}
                                                    </div>
                                                    Upload Hero Image <Icon icon='material-symbols:upload' />
                                                </div>
                                            </FileUploader>
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                }

            ]
        },
        {title: 'Quick Info',
            section: [
                {
                    label: 'List',
                    body: <ImageListComponent handleEdit={(obj) => setModalOpen({...modalOpen,quickInfo: obj})} handleDelete={(i) => handleRemoveQuickInfo(i)} data={data?.quickInfo} imageKey={'image'} />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,quickInfo: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Add <Icon icon='material-symbols:add' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.quickInfo} setOpen={() => setModalOpen({...modalOpen,quickInfo: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};
                                    const index = typeof(modalOpen?.quickInfo?.index) === 'number' ? modalOpen?.quickInfo?.index : -1;
                                    const objData = modalOpen?.quickInfo?.obj;
                                    const title = index !== -1 ? value?.quickInfo?.at(index)?.title || objData?.title : undefined
                                    const description = index !== -1 ? value?.quickInfo?.at(index)?.description || objData?.description : undefined
                                    const other = index !== -1 ? value?.quickInfo?.at(index)?.other || objData?.other : undefined
                                    let image = objData?.image;
                                    if(value?.quickInfo?.at(index)?.image)
                                        image = value?.quickInfo?.at(index)?.image;
                                    if(image && !isUrl(image))
                                        image = 'data:image/jpeg;base64,'+image

                                    
                                    console.log(index,objData,value?.quickInfo)
                                    
                                    async function handleBG(file) {
                                        // setBG({file,url: window.URL.createObjectURL(file)})
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(file);
                                        fileReader.onload = () => {
                                            const base64Data = fileReader.result.split(',')[1];
                                            // setValue({quickInfo: [...(data.quickInfo || []),{...(value||{}),image: base64Data}]})
                                            handleChange({image: base64Data})
                                        }
                                    }

                                    function handleChange(obj) {
                                        try {
                                            const temp = [...(data?.quickInfo||[])];
                                            if(index === -1)
                                                temp.push({})

                                            console.log(' -> ',temp,data?.quickInfo);
                                            const ind = index !== -1 ? index : ((temp?.length || 0) - 1);

                                            temp[ind] = {...(value?.quickInfo?.at(ind) || objData || {}),...(obj||{})}
                                            setValue({quickInfo: temp});
                                            console.log({quickInfo: temp})
                                        } catch(ex) {console.log(ex)}
                                    }
                                
                                    return (
                                        <div className='flex flex-col gap-4'>
                                            <FileUploader
                                                handleChange={handleBG} name={'files'} types={["JPG","JPEG","PNG","GIF"]}
                                            >
                                                <div className='border rounded-md p-4 flex gap-4 items-center text-blue-700'>
                                                    <div className='w-20 h-20 bg-primary/30 flex items-center justify-center rounded-md'>
                                                        {image ?
                                                            <img src={image} className='w-full h-full object-cover' />
                                                        :<Icon icon='ph:image-thin' className='text-primary/50' />}
                                                    </div>
                                                    Upload Image <Icon icon='material-symbols:upload' />

                                                </div>
                                            </FileUploader>
                                            <TextInput value={title} label='Title' onChange={(ev) => handleChange({title: ev.target.value})} />
                                            <TextInput value={description} multiline rows={4} label='Description' onChange={(ev) => handleChange({description: ev.target.value})} />
                                            <TextInput value={other} label='Page Link' onChange={(ev) => handleChange({other: ev.target.value})} />
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>

                    </div>
                }
            ]
        },
        {title: 'Banners',
            section: [
                {
                    label: 'List',
                    body: <ImageListComponent handleDelete={(i) => handleRemoveBanner(i)} data={data?.banners} imageKey={'image'} />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,banners: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Add <Icon icon='material-symbols:add' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.banners} setOpen={() => setModalOpen({...modalOpen,banners: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};

                                    async function handleBG(file) {
                                        // setBG({file,url: window.URL.createObjectURL(file)})
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(file);
                                        fileReader.onload = () => {
                                            const base64Data = fileReader.result.split(',')[1];
                                            setValue({banners: [...(data.banners || []),{image: base64Data,link: value?.banners?.link||''}]})
                                        }
                                    }
                                
                                    return (
                                        <div className='flex flex-col gap-4'>
                                            <FileUploader
                                                handleChange={handleBG} name={'files'} types={["JPG","JPEG","PNG","GIF"]}
                                            >
                                                <div className='border rounded-md p-4 flex gap-4 items-center text-blue-700'>
                                                    <div className='w-20 h-20 bg-primary/30 flex items-center justify-center rounded-md'>
                                                        {value?.banners?.length ?
                                                            <img src={'data:image/jpeg;base64,'+value?.banners?.at(-1)?.image} className='w-full h-full object-cover' />
                                                        :<Icon icon='ph:image-thin' className='text-primary/50' />}
                                                    </div>
                                                    Upload Banner Image <Icon icon='material-symbols:upload' />

                                                </div>
                                            </FileUploader>
                                            <TextInput label='Page Link' value={value?.link} onChange={(ev) => setValue({banners: [...(data.banners||[]),{image: value?.banners?.at(-1)?.image,link: ev.target.value}]})} />
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                }
            ]
        },
        {title: 'Recommended Destinations',
            section: [
                {
                    label: 'Destinations',
                    body: <ImageListComponent handleEdit={(obj) => setModalOpen({...modalOpen,destination: obj})} handleDelete={(i) => handleRemoveDestination(i)} data={data?.destinations} 
                        render={(obj,i) => {
                            const query = JSON.parse(obj?.query||'{}');
                            return (
                                <div key={i} className='w-full relative text-white'>
                                    <img src={getImageSource(obj['image'])} className='w-full h-44 object-cover' />
                                    <div className='p-2 absolute bottom-0 bg-gradient-to-b from-transparent to-[#000] w-full'>
                                        {obj?.title}
                                        <div className='flex gap-1 items-center text-xs'>
                                            <Icon icon='mdi:location' className='!w-3 !h-4' />
                                            <small>
                                                {query?.originDestinations?.at(0)?.from?.city},&nbsp;
                                                {query?.originDestinations?.at(0)?.to?.city}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,destination: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Add <Icon icon='material-symbols:add' className='!w-4 !h-4' /></button>
                        <EditModal data={modalOpen?.destination?.obj} open={modalOpen.destination} setOpen={() => setModalOpen({...modalOpen,destination: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};
                                    console.log('modal on: - ',value)
                                    const index = typeof(modalOpen?.destination?.index) === 'number' ? modalOpen?.destination?.index : -1;
                                    const objData = modalOpen?.destination?.obj;
                                    const title = index !== null ? value?.destinations?.at(index)?.title || objData?.title : undefined
                                    let image = objData?.image;
                                    if(value?.destinations?.at(index)?.image)
                                        image = value?.destinations?.at(index)?.image;
                                    if(image && !isUrl(image))
                                        image = 'data:image/jpeg;base64,'+image


                                    let query = objData?.query && (JSON.parse(objData?.query||'{}'));
                                    if(value?.destinations?.at(index)?.query)
                                        query = JSON.parse(value?.destinations?.at(index)?.query || '{}')
                                    console.log('Modal on: ',index,objData)

                                    async function handleBG(file) {
                                        // setBG({file,url: window.URL.createObjectURL(file)})
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(file);
                                        fileReader.onload = () => {
                                            const base64Data = fileReader.result.split(',')[1];
                                            // setValue({destination: {...value?.destination,image: base64Data}})
                                            handleChange({image: base64Data})
                                        }
                                    }

                                    function handleChange(obj,searchObj) {
                                        try {
                                            const temp = [...(data?.destinations||[])];
                                            if(index === -1)
                                                temp.push({})
                                            console.log(' -> ',index,temp,data?.destinations);
                                            const ind = index !== -1 ? index : ((temp?.length || 0) - 1);
                                            temp[ind] = {...(value?.destinations?.at(ind) || objData || {}),...(obj||{})}
                                            // return console.log('modal on: ',ind,temp,searchObj)
                                            setValue({destinations: temp});
                                            console.log({destinations: temp,searchObj})
                                        } catch(ex) {console.log(ex)}
                                    }

                                
                                    return (
                                        <div className=''>
                                            <TextInput label={'Title'} value={title} onChange={(ev) => handleChange({title: ev.target.value})} />
                                            <CreateFlightOrder data={query || undefined} callback={(searchObj) => handleChange({query: JSON.stringify(searchObj||{})},searchObj)} config={{getStateUpdate: true,hide: ['class']}} />
                                            <FileUploader
                                                handleChange={handleBG} name={'files'} types={["JPG","JPEG","PNG","GIF"]}
                                            >
                                                <div className='border rounded-md p-4 flex gap-4 items-center text-blue-700'>
                                                    <div className='w-20 h-20 bg-primary/30 flex items-center justify-center rounded-md'>
                                                        {image ?
                                                            <img src={image} className='w-full h-full object-cover' />
                                                        :<Icon icon='ph:image-thin' className='text-primary/50' />}
                                                    </div>
                                                    Upload Destination Image <Icon icon='material-symbols:upload' />
                                                </div>
                                            </FileUploader>
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                }
            ]
        }
    ]

    let currentHost = window.location.protocol + "//"+ window.location.host;
    const iframeRefScale = useRef();
    const [scale,setScale] = useState(.3);

    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
          setScale((iframeRefScale.current.offsetWidth / screenWidth));
        }
    }
      useEffect(()=>{
        window.addEventListener('resize', updateScale)
        updateScale()
        return ()=>{
          window.removeEventListener('resize', updateScale)
        }
      },[])

    
  return (
    <div className='pd-md flex gap-4 flex-wrap md:flex-nowrap overflow-hidden'>
        <div className='md:w-[65%]'>
            <div className='pb-4'>
                <h3>Edit home page content</h3>
                <p>These logo, colors, and font will be used to design your site. You can always change them later.</p>
            </div>
            <div className='flex flex-col gap-4'>
                {
                    sections?.map((section,i) => (
                        <div>
                            <div className='bg-theme1/10 p-4 py-2'>{section?.title}</div>
                            {section?.section?.map((obj,j) => (
                                <div key={section?.title+obj?.label+j} className='flex gap-4 p-4 border-b'>
                                    <div className='w-[150px]'>{obj?.label}</div>
                                    <div className='flex-1 max-w-[64%] overflow-hidden'>{obj?.body}</div>
                                    <div>{obj?.action(section)}</div>
                                </div>
                            ))}
                        </div>
                    ))
                }
            </div>
        </div>
        <div
            className="origin-top-left md:w-[30%]"
        >
            <iframe src={currentHost+'/order/new/flight/template/1'} className='w-screen origin-top-left overflow-hidden min-h-[90vh]'
                style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
            />
        </div>
    </div>
  )
}

function getImageSource(val) {
    if((val||"")?.startsWith('http'))
        return val;
    else return "data:image/jpeg;base64,"+val
}

function ImageListComponent({data,handleDelete,handleEdit,imageKey,render}) {

    if(!data?.length) return null;

    return (
        (
            <div className='flex gap-4 max-w-full flex-wrap'>
                {data?.map((obj,i) => (
                    <div key={i} onClick={() => {
                            handleEdit && handleEdit({obj,index:i})
                        }}>
                        <div className='w-40 relative'>
                            <div className='absolute z-10 top-0 right-0 p-1 rounded-md m-2 bg-red-200 cursor-pointer'
                                onClick={(ev) => {
                                    ev?.stopPropagation();
                                    handleDelete && handleDelete(i)
                                }}
                             >
                                <Icon icon='material-symbols:delete-outline' className='text-red-500 !w-5 !h-5' />
                            </div>
                            {!render ? 
                                <img src={getImageSource(imageKey ? obj[imageKey] : obj)} className='w-full h-32 object-cover' />
                            :
                                render(obj,i)
                            }
                        </div>
                    </div>
                ))}
            </div>
        )
    )
}
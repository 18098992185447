import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RatingStars from "../../../components/common/RatingStars";
import GoogleMapReact from "google-map-react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import { FormControl, MenuItem, Select } from "@mui/material";
import RoomCard from "../../../components/accommodation/RoomCard";
import { useHotels } from "../../../hooks/useHotels";
import Loader from "../../../utils/loader";
import { useSelector } from "react-redux";
import { GoogleAPI } from "../../../utils/key";
import { URLdecode, URLencode } from "../../../utils/url";
import EditTravelersModal from "../../../components/modal/EditTravelersModal";
import useDateFormat from "../../../hooks/useDateFormat";
import { useSnackbar } from "notistack";

function AccommodationHotelPage() {
  const navigate = useNavigate();
  const roomRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { compareDate, formatDate, isPastDate } = useDateFormat();
  const { getHotelAvailability, travellersString, checkRooms, recheckRooms } =
    useHotels();
  const { hotelAvailability: availability } = useSelector(
    (state) => state.hotelData
  );
  const { rooms, reservedRooms } = useSelector((state) => state.data);
  const params = URLdecode();

  const calendarRef = useRef([...Array(10)]);

  // states
  const [loading, setLoading] = useState(false);
  const [travelersModal, setTravelersModal] = useState(false);
  const [startDate, setStartDate] = useState(params?.start_date);
  const [endDate, setEndDate] = useState(params?.end_date);
  const [hotelAvailability, setHotelAvailability] = useState(availability);

  const recheckAvailability = async () => {
    // const data = await recheckRooms();
    // if (data) {
    //   setHotelAvailability(data);
    // } else {
    // }
    setHotelAvailability(availability);
  };

  const loadData = async () => {
    await getHotelAvailability(setLoading);
  };

  useEffect(() => {
    loadData();
  }, [params?.start_date, params?.end_date, params?.rooms]);

  useEffect(() => {
    recheckAvailability();
  }, [availability]);

  const handleSetDate = (val, index) => {
    if (index === 0) {
      setStartDate(val);
    } else if (index === 1) {
      setEndDate(val);
    }

    if (calendarRef.current?.at(index + 1)) {
      calendarRef.current[index + 1]?.toggle(
        calendarRef.current[index + 1]?.ref?.current
      );
    }
    calendarRef.current[index]?.toggle();
  };

  const handleNextRoom = () => {
    if (roomRef.current) {
      roomRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const handlePreviousRoom = () => {
    if (roomRef.current) {
      roomRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const images = () => {
    let totalImages = [];
    if (hotelAvailability) {
      let imgs = hotelAvailability?.details?.image;
      if (imgs) {
        totalImages = imgs;
      }
    }

    return totalImages;
  };

  const rating = (hotel) => {
    let stars = null;

    const awards = hotel?.details?.awards;

    if (awards) {
      let star = awards.find((award) => award.RatingSymbol === "Star");
      if (star) {
        if (parseInt(star?.Rating)) {
          return parseInt(star?.Rating);
        }
      }
    }

    return stars;
  };

  const popularAmenities = () => {
    let totalAmenities = "";
    if (hotelAvailability) {
      let facilities = hotelAvailability?.details?.facilities;
      if (facilities) {
        let data = facilities || [];
        let dat = data.filter((item) => {
          if (!item?.description && !item?.title) {
            return false;
          } else {
            return true;
          }
        });
        data = dat;

        data = data.map((item) => {
          let name = "";
          if (item?.title) {
            name = item?.title;
          } else {
            name = item?.description;
          }

          name = name.replace(/_/g, " ");
          name = name.replace(",HAC", " ");
          name = name.toLocaleLowerCase();
          name = name.charAt(0).toUpperCase() + name.slice(1);

          let amenity = {
            code: parseInt(item?.code),
            title: name,
          };
          return amenity;
        });
        for (const item of data) {
          totalAmenities += `, ${item.title}`;
        }
        totalAmenities = totalAmenities.replace(", ", "");
      }
    }

    return totalAmenities;
  };

  const amenities = () => {
    let totalAmenities = [];
    if (hotelAvailability) {
      let facilities = hotelAvailability?.details?.facilities;
      if (facilities) {
        let data = facilities || [];
        let dat = data.filter((item) => {
          if (!item?.description && !item?.title) {
            return false;
          } else {
            return true;
          }
        });
        data = dat;

        data = data.map((item) => {
          let name = "";
          if (item?.title) {
            name = item?.title;
          } else {
            name = item?.description;
          }

          name = name.replace(/_/g, " ");
          name = name.replace(",HAC", " ");
          name = name.toLocaleLowerCase();
          name = name.charAt(0).toUpperCase() + name.slice(1);

          let amenity = {
            code: parseInt(item?.code),
            title: name,
          };
          return amenity;
        });
        for (const item of data) {
          totalAmenities.push(item?.title);
        }
      }
    }

    return totalAmenities;
  };

  const handleCheckAvailability = () => {
    if (isPastDate(startDate)) {
      enqueueSnackbar("Start date can't be a past date", { variant: "error" });
      return;
    }
    if (isPastDate(endDate)) {
      enqueueSnackbar("End date can't be a past date", { variant: "error" });
      return;
    }
    if (compareDate(endDate, startDate)) {
      enqueueSnackbar("End date can't be before start date", {
        variant: "error",
      });
      return;
    }
    const parameters = {
      ...params,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      rooms: JSON.stringify(rooms),
    };
    navigate(`/accommodation/hotel?${URLencode(parameters)}`);
  };

  const handleNext = () => {
    const parameters = {
      ...params,
      reservedRooms: JSON.stringify(reservedRooms),
      images: JSON.stringify(images()),
      chainCode: params?.chainCode || hotelAvailability?.chainCode,
    };
    navigate(`/accommodation/userinfo?${URLencode(parameters)}`);
  };

  return (
    <div className={Classname.container}>
      <EditTravelersModal open={travelersModal} setOpen={setTravelersModal} />
      <Loader loading={loading} className="mt-4 mb-2" />
      {hotelAvailability && !loading && (
        <div className={Classname.content}>
          {/* Page title */}
          <div className={Classname.titleContainer}>
            {`Stays > Orders > New Order >`}{" "}
            <span className={Classname.titleMain}>
              {hotelAvailability?.chainName}
            </span>
          </div>
          {/* Images container */}
          {images() && images()?.length > 0 && (
            <div className={Classname.imagesContainer}>
              <div className={Classname.mainImageContainer}>
                <img src={images()[0]} className={Classname.mainImage} alt="" />
              </div>
              <div className={Classname.imageRow}>
                {images()
                  ?.slice(1)
                  ?.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      className={Classname.imageRowElement}
                      alt=""
                    />
                  ))}
              </div>
            </div>
          )}

          {/* Detail container */}
          <div className={Classname.detailContainer}>
            {/* Detail */}

            <div className={Classname.detailContent}>
              <div className={Classname.detailNameContainer}>
                <div className={Classname.detailNameContent}>
                  <span className={Classname.detailName}>
                    {hotelAvailability?.name}
                  </span>
                  <p className="mb-3">{hotelAvailability?.details?.text}</p>
                </div>
                {rating(hotelAvailability) && (
                  <div className={Classname.detailRatingContainer}>
                    <span>{rating(hotelAvailability)}/5</span>
                    <RatingStars rating={rating(hotelAvailability)} />
                  </div>
                )}
              </div>
              {popularAmenities() && (
                <>
                  <span className={Classname.detailTitle}>
                    Popular Amenities
                  </span>
                  <p className="mb-3"> {popularAmenities()}</p>
                </>
              )}
              {/* <div className={Classname.detailExperienceDetailContainer1}>
                <div className={Classname.detailAboutTag}>
                  <img
                    src="/IconTime.svg"
                    alt=""
                    className={Classname.detailAboutTagIcon}
                  />
                  <div className={Classname.detailAboutTagContentContainer}>
                    <span className={Classname.detailAboutTagName}>
                      Valid for one(1) day
                    </span>
                    <span className={Classname.detailAboutTagDescription}>
                      from first activation
                    </span>
                  </div>
                </div>
                <div className={Classname.detailAboutTag}>
                  <img
                    src="/IconSound.svg"
                    alt=""
                    className={Classname.detailAboutTagIcon}
                  />
                  <div className={Classname.detailAboutTagContentContainer}>
                    <span className={Classname.detailAboutTagName}>
                      Audio guide included
                    </span>
                    <span className={Classname.detailAboutTagDescription}>
                      English, French, German, Spanish, Italian
                    </span>
                  </div>
                </div>
                <div className={Classname.detailAboutTag}>
                  <img
                    src="/IconWheelchair.svg"
                    alt=""
                    className={Classname.detailAboutTagIcon}
                  />
                  <div className={Classname.detailAboutTagContentContainer}>
                    <span className={Classname.detailAboutTagName}>
                      Wheelchair accessible
                    </span>
                  </div>
                </div>
              </div> */}
              {/* <span className={Classname.detailTitle}>About</span>
              <div className="flex flex-1 relative pb-4">
                <p>{readMore ? about : about.slice(0, 300) + " ...."}</p>
                {!readMore && (
                  <div
                    className={Classname.detailReadMoreButton}
                    onClick={OnReadMore}
                  >
                    read more
                  </div>
                )}
              </div>
              <span className={Classname.detailTitle}>This property</span>
              <p>
                Tribe London Canary Wharf is located in Canary Wharf, a
                neighborhood in London, and is near a metro station. O2 Arena
                and The British Museum are cultural highlights, and some of the
                area's popular attractions include London Eye and Natural
                History Museum. Looking to enjoy an event or a game? See what's
                going on at ExCeL Exhibition Centre or Wembley Stadium.
              </p> */}
              {hotelAvailability?.position?.latitude &&
                hotelAvailability?.position?.longitude && (
                  <>
                    <span className={Classname.detailTitle}>Map</span>
                    <div
                      style={{ width: "100%" }}
                      className={Classname.detailMap}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key:
                            process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
                            GoogleAPI,
                        }}
                        defaultCenter={{
                          lat: parseFloat(
                            hotelAvailability?.position?.latitude
                          ),
                          lng: parseFloat(
                            hotelAvailability?.position?.longitude
                          ),
                        }}
                        defaultZoom={15}
                      ></GoogleMapReact>
                    </div>
                  </>
                )}

              <span className={Classname.detailTitle}>Amenities</span>
              <div className={Classname.detailAmenitiesContainer}>
                <div className={Classname.detailAmenities}>
                  <div className={Classname.detailAmenitiesTitleContainer}>
                    <span>Property</span>
                  </div>
                  <div className={Classname.detailAmenitiesDetailContainer}>
                    {amenities().map((amenity, index) => (
                      <div
                        className={Classname.detailAmenitiesDetail}
                        key={index}
                      >
                        {amenity}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className={Classname.detailChangeContainer}>
                <div className={Classname.detailInputContainer}>
                  <span>From</span>
                  <CalendarInput1
                    ref={(el) => (calendarRef.current[0] = el)}
                    className={Classname.detailInput}
                    value={startDate || ""}
                    onChange={(value) =>
                      handleSetDate(value?.start || value, 0)
                    }
                    config={{ validDates: [new Date()] }}
                  />
                </div>
                <div className={Classname.detailInputContainer}>
                  <span>To</span>
                  <CalendarInput1
                    ref={(el) => (calendarRef.current[1] = el)}
                    className={Classname.detailInput}
                    value={endDate || ""}
                    onChange={(value) =>
                      handleSetDate(value?.start || value, 1)
                    }
                    config={{ validDates: [startDate || new Date()] }}
                  />
                </div>
                <div className={Classname.detailInputContainer}>
                  <span className={Classname.formInputLabel}>Travelers</span>
                  <input
                    type="text"
                    value={travellersString(true)}
                    onClick={() => setTravelersModal(true)}
                    className="w-full h-11 rounded-md px-4 border border-gray-300 text-sm"
                  />
                </div>
                <button
                  className={Classname.detailChangeButton}
                  onClick={handleCheckAvailability}
                >
                  Check availability
                </button>
              </div>
              {hotelAvailability?.rooms && (
                <>
                  <span className={Classname.detailTitle}>Choose a room</span>
                  <div className={Classname.detailRoomButtons}>
                    <div
                      className={Classname.detailRoomButton}
                      onClick={handleNextRoom}
                    >{`<`}</div>
                    <span>---</span>
                    <div
                      className={Classname.detailRoomButton}
                      onClick={handlePreviousRoom}
                    >{`>`}</div>
                  </div>

                  <div ref={roomRef} className={Classname.detailRoomsContainer}>
                    {hotelAvailability?.rooms?.map((room, index) => (
                      <RoomCard key={index} room={room} index={index} />
                    ))}
                  </div>
                </>
              )}
              {reservedRooms?.length > 0 ? (
                <div className={Classname.ctaButtonContainer}>
                  <button className={Classname.ctaButton} onClick={handleNext}>
                    Book rooms
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccommodationHotelPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 ",
  titleContainer: "w-full h5 my-10",
  titleMain: "text-black",

  imagesContainer: "flex flex-col w-full gap-2",
  mainImageContainer: "relative w-full h-64 lg:h-[600px]",
  mainImage: "rounded-xl w-full h-full object-cover",
  mainImageCategory:
    "font-bold text-xxxs absolute top-2 left-2 bg-white/70 py-1 px-2 rounded-md",
  mainImageCTA:
    "font-bold text-xxxs absolute bottom-2 right-2 bg-white/70 py-1 px-2 rounded-md cursor-pointer",
  imageRow: "flex flex-1 overflow-scroll scroll-hide gap-2",
  imageRowElement: "w-32 lg:w-64 h-32 lg:h-64 rounded-xl object-cover",

  detailContainer: "flex w-full gap-6 mt-6",
  detailContent: "flex flex-col flex-1 w-full",
  detailTitle: "text-xl font-bold mb-1 mt-8",
  detailLocationContainer: "flex items-center gap-2 text-gray-400 mb-10",
  detailLocationIcon: "",
  detailDescription: "text-gray-600 mb-6",
  detailAboutTagsContainer: "flex flex-wrap gap-4 mb-6",
  detailAboutTag: "flex items-center h-20 bg-primary1/10 px-6 gap-4 rounded-md",
  detailAboutTagIcon: "",
  detailAboutTagContentContainer: "flex flex-col",
  detailAboutTagName: "font-bold text-lg",
  detailAboutTagDescription: "text-sm text-gray-600",
  detailContentFormInputs: "flex flex-wrap gap-2 w-full mt-10 mb-20",
  detailContentFormInputContainer: "flex flex-col flex-1",
  detailContentFormInputLabel: "font-bold mb-1 text-gray-600",
  datailContentFormDateInput:
    "w-full border border-primary/20 rounded-md p-2 h-14",
  detailContentFormButton:
    "flex bg-primary1 font-bold text-white rounded-lg h-14 items-center justify-center mt-7",
  detailOverviewDescription: "mb-10",

  detailNameContainer:
    "flex flex-col md:flex-row w-full justify-between gap-10",
  detailName: "font-bold text-xl mb-2",
  detailNameContent: "flex flex-col flex-1",
  detailRatingContainer: "flex flex-col items-end",
  detailMap: "h-80",

  detailRoomsContainer: "w-full flex gap-10 overflow-scroll scroll-hide",

  detailChangeContainer: "flex flex-wrap w-full gap-4 mt-20 mb-4",
  detailInputContainer: "flex flex-col flex-1",
  detailInput: "w-full border border-primary/20 rounded-md p-2",
  detailChangeButton:
    "flex flex-1 h-12 items-center justify-center rounded-md text-white bg-primary1 font-bold mt-5",
  detailRoomButtons:
    "w-full flex items-center gap-3 justify-center font-bold text-xl mb-10",
  detailRoomButton:
    "flex w-12 h-12 rounded-md bg-gray-200 items-center justify-center font-bold text-lg hover:text-white hover:bg-primary1 cursor-pointer",

  detailReadMoreButton:
    "w-full h-10 flex items-center justify-center cursor-pointer absolute bottom-0 bg-white/80",

  detailExperienceDetailContainer1:
    "flex flex-wrap flex-1 gap-3 text-gray-500 mt-3",
  detailAmenitiesContainer: "flex flex-1 flex-col",
  detailAmenities: "flex flex-col lg:flex-row gap-10 pt-10",
  detailAmenitiesTitleContainer: "flex w-72 font-bold",
  detailAmenitiesDetailContainer: "flex flex-1 flex-wrap gap-5 mb-4",
  detailAmenitiesDetail: "flex w-1/4 gap-6",
  detailAmenity: "flex flex-col",
  detailAmenityTitleContainer: "flex items-center gap-2 mb-2",
  detailAmenityIcon: "",
  detailAmenityDetail: "text-sm text-gray-500",
  ctaButtonContainer: "w-full flex items-center justify-center mb-20 mt-10",
  ctaButton: "w-72 rounded-md bg-primary1 font-bold text-white h-12",
};

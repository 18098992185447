import { Check } from "@mui/icons-material";
import { useState } from "react";

const passengersData = [
  {
    name: "Chinedu Ike",
    type: "Male",
    ticketNo: "72839483728932",
    selected: false,
    voidSubmitted: true,
  },
  {
    name: "Chinedu Ike",
    type: "Male",
    ticketNo: "72839483728932",
    selected: false,
  },
];

const TicketManagementPassengerSelection = () => {
  const [passengers, setPassengers] = useState(passengersData);
  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold mb-2 text-black">Select Passenger</span>
      {passengers.map((passenger, index) => (
        <div
          key={index}
          className={`flex flex-col gap-2 w-full px-2 py-2 rounded-md bg-white`}
        >
          <div className="w-full flex justify-between items-center">
            <span className="text-sm ext-gray-400">Passenger {index + 1} </span>
            <div
              className={`w-8 h-8 rounded-sm text-xs flex lg:hidden items-center justify-center cursor-pointer ${
                passenger.selected
                  ? "bg-primary1 text-white"
                  : "text-[#5A657C] bg-[#CFD9E0]"
              }`}
              onClick={() => {
                setPassengers(
                  passengers.map((p, idx) =>
                    idx === index ? { ...p, selected: !p.selected } : p
                  )
                );
              }}
            >
              <Check color={"inherit"} fontSize="inherit" />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between gap-4">
            <div className="flex flex-col flex-1 gap-1 lg:gap-2 p-2 lg:p-4 border border-primary1 rounded-md">
              <span className="font-bold mb-2 text-black">Passenger</span>
              <span className="text-sm">{passenger.name}</span>
            </div>
            <div className="flex flex-col flex-1 gap-1 lg:gap-2 p-2 lg:p-4 border border-primary1 rounded-md">
              <span className="font-bold mb-2 text-black">Passenger type</span>
              <span className="text-sm">{passenger.type}</span>
            </div>
            <div className="flex flex-col flex-1 gap-1 lg:gap-2 p-2 lg:p-4 border border-primary1 rounded-md">
              <span className="font-bold mb-2 text-black">Ticket No</span>
              <span className="text-sm">{passenger.ticketNo}</span>
            </div>
            <div
              className={`w-8 h-8 rounded-sm text-xs hidden lg:flex items-center justify-center cursor-pointer ${
                passenger.selected
                  ? "bg-primary1 text-white"
                  : "text-[#5A657C] bg-[#CFD9E0]"
              }`}
              onClick={() => {
                setPassengers(
                  passengers.map((p, idx) =>
                    idx === index ? { ...p, selected: !p.selected } : p
                  )
                );
              }}
            >
              <Check color={"inherit"} fontSize="inherit" />
            </div>
          </div>
          {passenger?.voidSubmitted && (
            <div className="text-primary1 uppercase bg-primary1/10 rounded-md py-1 px-3 w-fit text-xs">
              Void request submitted
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TicketManagementPassengerSelection;

const Classname = {
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
};

import { useState } from "react";

const TicketManagementContactDetails = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">Contact details</span>
      <span className="text-sm text-gray-500 mb-2">
        Enter details where updates regarding request will be sent
      </span>
      <div className="flex flex-wrap flex-col lg:flex-row gap-4 w-full">
        <input
          type="text"
          placeholder="Contact name"
          className={Classname.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="E-mail"
          className={Classname.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="Phone number"
          className={Classname.input}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
    </div>
  );
};

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};

export default TicketManagementContactDetails;

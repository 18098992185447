import { Instagram, Facebook, Twitter } from '@mui/icons-material';
import Logo from '../Utils/Header/Logo';
import { Link } from 'react-router-dom';
import { getsubDomain, isCustomerSite } from '../../../utils/getsubDomain';
import FooterContact from './FooterContact';

const Footer = ({state, curFooter})=>{
    const customer = isCustomerSite();
    const url = !customer ? '/preview/template' : '';
    return (
        <div className="py-16 bg-[#EBEFF9] justify-center px-10 md:px-36">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:gap-48 items-center mb-4 pb-6" style={{borderBottom: "1px solid #0A142F"}}>
                <div className="col-span-1 grid gap-2 text-center">
                    <Logo state={state} />
                    <p>Plan your next trip with us. Search low prices on flights,hotels,tours, insurance and much more...</p>
                </div>
                <div className={`grid gap-4 `}>
                    <div>
                        <span className='font-bold'>About</span>
                        <div className="flex flex-wrap gap-x-16 justify-between">
                            {state?.selectedPages?.includes('about')?
                                <Link to={url+'/about'}>About us</Link>
                            :null}
                            {state?.selectedPages?.includes('contact')?
                                <Link to={url+'/contact'}>Contact</Link>
                            :null}
                            {state?.selectedPages?.includes('t&c')?
                                <Link to={url+'/terms'}>T&Cs</Link>
                            :null}
                        </div>
                    </div>
                    <div>
                        <span className='font-bold'>Contact</span>
                        <FooterContact />
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 justify-between w-full'>
                <div className="flex gap-3">
                    
                        <Instagram style={{color: state.secondaryColor}}/>
                    
                    
                        <Facebook  style={{color: state.secondaryColor}}/>
                    
                    
                        <Twitter  style={{color: state.secondaryColor}}/>
                    
                </div>
                <div className='flex justify-end'>
                    <p>© 2024 {getsubDomain()}. All rights reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
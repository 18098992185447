import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTourActivity } from "../../redux/reducers/tour/tourDataSlice";
import { formatMoney } from "../../features/utils/formatMoney";
import { URLdecode, URLencode } from "../../utils/url";
import ISO6391 from "iso-639-1";
import RatingStars from "../common/RatingStars";
import { useTours } from "../../hooks/useTours";

function TourCard1({ tour }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getDuration } = useTours();
  const [tags, setTags] = useState([]);

  const parseTags = () => {
    let allTags = [];
    if (tour?.tags) {
      let displayableTags = [21972, 21873, 22083, 11940, 21074];
      let availableTags = tour?.tags;
      availableTags = availableTags?.filter((tag) => {
        let available = displayableTags.find((tg) => tg === tag?.tagId);
        if (available) return true;
        return false;
      });

      allTags = availableTags;
    }

    setTags(allTags);
  };

  useEffect(() => {
    parseTags();
  }, [tour]);

  const handleClick = () => {
    const params = URLdecode();
    dispatch(setTourActivity(tour));

    let paxMix = [];

    paxMix = tour?.pricingInfo?.ageBands || [];

    let ageAdult = paxMix?.find((mix) => mix.ageBand === "ADULT");

    paxMix = paxMix.map((age, index) => {
      return {
        startAge: age?.startAge,
        endAge: age?.endAge,
        ageBand: age?.ageBand,
        numberOfTravelers:
          age?.ageBand === "ADULT" || (!ageAdult && index === 0)
            ? Math.max(age?.minTravelersPerBooking, 1)
            : 0,
      };
    });

    const parameters = {
      ...params,
      productCode: tour?.productCode,
      supplier: tour?.package_supplier,
      tourDuration: getDuration(tour),
      paxMix: JSON.stringify(paxMix),
    };
    let url = `/tour/singleTour?${URLencode(parameters)}`;
    window.location.href = url;

    // navigate(`/tour/singleTour?${URLencode(parameters)}`);
  };

  const coverImage = () => {
    let cover = "";
    if (tour) {
      if (tour?.images) {
        tour?.images?.map((image, index) => {
          if (image?.isCover === true) {
            cover = image?.variants[12]?.url;
          }
        });
        if (!cover) {
          cover = tour?.images[0]?.variants[12]?.url;
        }
      }
    }
    return cover;
  };

  const rating = (num) => {
    if (tour?.reviews) {
      if (tour?.reviews?.sources) {
        if (num) {
          return tour?.reviews?.sources[0]?.averageRating;
        } else {
          return `${tour?.reviews?.sources[0]?.averageRating} (${tour?.reviews?.sources[0]?.reviewCounts?.length}) reviews`;
        }
      }
    }

    return "";
  };

  const Price = () => {
    let price = formatMoney(tour?.farePrice);
    if (!price) return <></>;
    return (
      <div className={Classname.priceContainerMobile}>
        <div>from</div>
        <div className={Classname.price}>{price}</div>
        <div>per adult</div>
      </div>
    );
  };

  const languages = () => {
    let languageString = "";

    let availableLanguages = tour?.languageGuides || [];

    for (let i = 0; i < availableLanguages.length; i++) {
      languageString +=
        i !== 0
          ? `, ${
              ISO6391.getName(availableLanguages[i]?.language) ||
              "Unkown language"
            }`
          : ISO6391.getName(availableLanguages[i]?.language) ||
            "Unkown language";
    }

    return languageString;
  };

  const url = () => {
    const params = URLdecode();
    // let paxMix = [];

    // paxMix = tour?.pricingInfo?.ageBands || [];

    // let ageAdult = paxMix?.find((mix) => mix.ageBand === "ADULT");

    // paxMix = paxMix.map((age, index) => {
    //   return {
    //     startAge: age?.startAge,
    //     endAge: age?.endAge,
    //     ageBand: age?.ageBand,
    //     numberOfTravelers:
    //       age?.ageBand === "ADULT" || (!ageAdult && index === 0)
    //         ? Math.max(age?.minTravelersPerBooking, 1)
    //         : 0,
    //   };
    // });

    const parameters = {
      ...params,
      productCode: tour?.productCode,
      supplier: tour?.package_supplier,
      tourDuration: getDuration(tour),
      // paxMix: JSON.stringify(paxMix),
    };

    let text = `/tour/singleTour?${URLencode(parameters)}`;

    return text;
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.imageContainer}>
        <img src={coverImage()} className={Classname.image} />
      </div>

      <div className={Classname.content}>
        <div className={Classname.nameContainer}>
          <span className={Classname.name}>{tour.title}</span>
          <Price />
        </div>
        <p className={Classname.description}>
          {tour?.description?.length > 150
            ? tour.description?.slice(0, 150) + " ..."
            : tour.description}
        </p>

        <div className={Classname.ratingContainer}>
          <span>
            Powered by:{" "}
            <span className={Classname.ratingLink}>{tour?.supplier?.name}</span>{" "}
          </span>
          {rating() && (
            <span>
              <RatingStars rating={rating(true)} />
            </span>
          )}
        </div>
        <div className={Classname.featuresAndButton}>
          <div className={Classname.featuresContainer}>
            {languages() && (
              <div className={Classname.feature}>
                <img
                  src="/IconBlackGlobe.svg"
                  alt=""
                  className={Classname.featureIcon}
                />
                {languages() && (
                  <div className={Classname.featureContent}>
                    <span>Languages</span>
                    <span className={Classname.featureDescription}>
                      {languages()}
                    </span>
                  </div>
                )}
              </div>
            )}
            {/* <div className={Classname.feature}>
              <img
                src="/IconBlackWatch.svg"
                alt=""
                className={Classname.featureIcon}
              />
              <div className={Classname.featureContent}>
                <span>Start Time</span>
                <span className={Classname.featureDescription}>
                  11:00 AM, 2:00 PM
                </span>
              </div>
            </div> */}
            <div className={Classname.feature}>
              <img
                src="/IconBlackTimer.svg"
                alt=""
                className={Classname.featureIcon}
              />
              <div className={Classname.featureContent}>
                <span>Duration</span>
                <span className={Classname.featureDescription}>
                  {getDuration(tour)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 justify-between w-full lg:w-fit">
            <Price />
            <a
              className={Classname.selectButton}
              target="_blank"
              rel="noopener noreferrer"
              href={url()}
            >
              Select
            </a>
          </div>
        </div>
        <div className={Classname.cardTagsContainer}>
          {tour.likelyToSellout && (
            <div className={Classname.likelyTag}>Likely to sellout</div>
          )}
          {tour.topPick && <div className={Classname.topTag}>Top Pick</div>}
          {tags?.map((tag, index) => (
            <div className={Classname.topTag} key={index}>
              {tag?.allNamesByLocale?.en}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TourCard1;

const Classname = {
  container:
    "w-full rounded-xl p-4 border border-gray-200 flex flex-col lg:flex-row",
  imageContainer:
    "w-full lg:w-56 h-56 lg:h-full flex items-center justify-center relative",
  image: "w-full h-full object-cover rounded-lg",
  content: "flex flex-1 flex-col p-4",
  nameContainer: "flex flex-col lg:flex-row items-center justify-between",
  name: "mb-2 font-bold text-lg lg:text-xl",
  priceContainer: "text-gray-600 font-bold lg:flex flex-col hidden gap-2",
  priceContainerMobile:
    "text-gray-600 font-bold flex lg:hidden flex-shrink-0 flex-col ",
  price: "font-bold text-lg text-black",
  description: "text-sm text-gray-500 mt-2 mb-4",
  ratingContainer:
    "flex w-full justify-between items-center text-sm text-gray-500",
  ratingLink: "text-primary1",
  featuresAndButton:
    "flex flex-col lg:flex-row w-full justify-between items-center mt-4",
  featuresContainer:
    "flex text-xs flex-row items-center gap-4 font-bold lg:text-sm flex-1 justify-between w-full",
  feature: "flex flex-col lg:flex-row items-start lg:items-center gap-2",
  featureContent: "flex flex-col",
  featureIcon: "",
  featureDescription: "font-normal text-gray-500",
  selectButton:
    "h-12 rounded-lg px-6 bg-primary1 flex items-center justify-center font-bold text-sm text-white mt-4 w-full lg:w-fit md:mt-0 lg:ml-10",
  cardTagsContainer: "flex flex-wrap gap-2 mt-2",
  likelyTag:
    "text-xxxs font-semibold text-red-700 bg-red-600/20 px-2 py-1 rounded-md",
  topTag:
    "text-xxxs font-semibold text-yellow-800 bg-yellow-600/20 px-2 py-1 rounded-md",
};

import { useSelector } from "react-redux";
import SearchBarList from "../Searchbars/searchBarList";
import TopDestination from "../dashboard/Home/TopDestination";
import Banner from "./Banner";
import HomeCarousel from "./HomeCarousel";
import GuideLinks from "./GuideLinks";
import FooterList from "../footers/footerList";

export function Layout1() {
    const state = useSelector((state)=> state.template);
    const {user} = useSelector(state => state.user.userData)

    const crmData = user?.detail?.crmData;

    return (
      <div
      className={`w-full h-auto rounded-lg`}
    >
        <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
          <HomeCarousel data={crmData?.hero?.images} />
          <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
          <div className="origin-top-left z-10" >
            <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20">
                <span className="flex flex-col gap-2 text-white">
                  <span style={{color: state.secondaryColor}}>Hello there,</span>
                  Book cheap flights with your one-step travel shop!
                </span>
            </div>
          </div>
          <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
            <div className="!w-full origin-top-left">
                <SearchBarList/>
            </div>
          </div>
        </div>
      
        <div className="px-10 md:px-24 my-10">
          <TopDestination />
        </div>
        <Banner />
        <GuideLinks />

        {/* <div>
          <Footer state={state} curFooter={state.footerStyle}/>
        </div> */}
      <div className="mt-10">
        <FooterList />
      </div>

    </div>
  
    )
  }

import { Instagram, Facebook, Twitter } from '@mui/icons-material';
import Logo from '../Utils/Header/Logo';
import { Link } from 'react-router-dom';
import FooterContact from './FooterContact';

const Footer2 = ({secondaryColor, state})=>{
    return (
        <div className="bg-[#c5cee3] font-poppins justify-center px-10 md:px-36 py-2">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:gap-48 my-4 pb-4 items-start gap-4" style={{borderBottom: "1px solid #0A142F"}}>
                <div className="flex flex-col gap-4">
                    <div className='flex text-center'>
                        <Logo state={state}/>
                    </div>
                    <p>
                        Plan your next trip with us. Search low prices on flights,hotels,tours, insurance and much more...
                    </p>
                </div>
                <div className="grid gap-4">
                    <h3 className='font-bold'>Quick links</h3>
                    <div className="flex flex-wrap gap-x-16 justify-between">
                        {state?.selectedPages?.includes('about')?
                            <Link to='/about'>About us</Link>
                        :null}
                        {state?.selectedPages?.includes('contact')?
                            <Link to='/contact'>Contact</Link>
                        :null}
                        {state?.selectedPages?.includes('t&c')?
                            <Link to='/terms'>T&Cs</Link>
                        :null}
                    </div>
                    <FooterContact />
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 justify-between py-2 items-center'>
                <div className='flex gap-3'>
                    <Instagram style={{color: secondaryColor}} />
                    <Facebook style={{color: secondaryColor}} />
                    <Twitter style={{color: secondaryColor}} />
                </div>
            </div>
        </div>
    )
}

export default Footer2
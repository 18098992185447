import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTourActivity } from "../../redux/reducers/tour/tourDataSlice";
import { formatMoney } from "../../features/utils/formatMoney";
import { URLdecode, URLencode } from "../../utils/url";
import { useTours } from "../../hooks/useTours";

function TourCard({ tour }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getDuration } = useTours();
  const [tags, setTags] = useState([]);

  const parseTags = () => {
    let allTags = [];
    if (tour?.tags) {
      let displayableTags = [21972, 21873, 22083, 11940, 21074];
      let availableTags = tour?.tags;
      availableTags = availableTags?.filter((tag) => {
        let available = displayableTags.find((tg) => tg === tag?.tagId);
        if (available) return true;
        return false;
      });

      allTags = availableTags;
    }

    setTags(allTags);
  };

  useEffect(() => {
    parseTags();
  }, [tour]);

  const coverImage = () => {
    let cover = "";
    if (tour) {
      if (tour?.images) {
        tour?.images?.map((image, index) => {
          if (image?.isCover === true) {
            cover = image?.variants[12]?.url;
          }
        });
        if (!cover) {
          cover = tour?.images[0]?.variants[12]?.url;
        }
      }
    }
    return cover;
  };

  const handleClick = () => {
    const params = URLdecode();
    dispatch(setTourActivity(tour));

    // let paxMix = [];

    // paxMix = tour?.pricingInfo?.ageBands || [];

    // let ageAdult = paxMix?.find((mix) => mix.ageBand === "ADULT");

    // paxMix = paxMix.map((age, index) => {
    //   return {
    //     startAge: age?.startAge,
    //     endAge: age?.endAge,
    //     ageBand: age?.ageBand,
    //     numberOfTravelers:
    //       age?.ageBand === "ADULT" || (!ageAdult && index === 0)
    //         ? Math.max(age?.minTravelersPerBooking, 1)
    //         : 0,
    //   };
    // });

    const parameters = {
      ...params,
      productCode: tour?.productCode,
      supplier: tour?.package_supplier,
      tourDuration: getDuration(tour),
      // paxMix: JSON.stringify(paxMix),
    };
    let url = `/tour/singleTour?${URLencode(parameters)}`;
    window.location.href = url;

    // navigate(`/tour/singleTour?${URLencode(parameters)}`);
  };

  const url = () => {
    const params = URLdecode();
    // let paxMix = [];

    // paxMix = tour?.pricingInfo?.ageBands || [];

    // let ageAdult = paxMix?.find((mix) => mix.ageBand === "ADULT");

    // paxMix = paxMix.map((age, index) => {
    //   return {
    //     startAge: age?.startAge,
    //     endAge: age?.endAge,
    //     ageBand: age?.ageBand,
    //     numberOfTravelers:
    //       age?.ageBand === "ADULT" || (!ageAdult && index === 0)
    //         ? Math.max(age?.minTravelersPerBooking, 1)
    //         : 0,
    //   };
    // });

    const parameters = {
      ...params,
      productCode: tour?.productCode,
      supplier: tour?.package_supplier,
      tourDuration: getDuration(tour),
      // paxMix: JSON.stringify(paxMix),
    };

    let text = `/tour/singleTour?${URLencode(parameters)}`;

    return text;
  };

  return (
    <a
      className={Classname.container}
      target="_blank"
      rel="noopener noreferrer"
      href={url()}
    >
      <div className={Classname.imageContainer}>
        {/* <span className={Classname.category}>{tour.category}</span> */}
        <img src={coverImage()} className={Classname.image} />
      </div>
      <div className={Classname.nameContainer}>
        <span>{tour.title}</span>
      </div>

      <div className={Classname.tagsContainer}>
        {tour?.logistics?.travelerPickup?.allowCustomTravelerPickup && (
          <span>Pickup available</span>
        )}
        {tags?.map((tag, index) => (
          <div className={Classname.topTag} key={index}>
            {tag?.allNamesByLocale?.en}
          </div>
        ))}
        {/* {tour.tags.map((tag, index) => (
          <span>
            {" "}
            {tag} {index !== tour.tags.length - 1 && "|"}
          </span>
        ))} */}
      </div>
      <div className="flex flex-1"></div>
      <div className={Classname.cardTagsContainer}>
        {tour.likeyToSellout && (
          <div className={Classname.likelyTag}>Likely to sellout</div>
        )}
        {tour.topPick && <div className={Classname.topTag}>Top Pick</div>}
      </div>
      {tour?.farePrice ? (
        <div className={Classname.priceContainer}>
          <span>
            From <strong>{formatMoney(tour?.farePrice)}</strong>
          </span>
          <span>Per Person</span>
        </div>
      ) : (
        <></>
      )}
    </a>
  );
}

export default TourCard;

const Classname = {
  container:
    "w-56 min-h-[320px] h-fit p-1 rounded-lg border border-gray-200 flex flex-col cursor-pointer flex-shrink-0",
  imageContainer: "w-full h-28 flex items-center justify-center relative",
  image: "w-full h-full object-cover rounded-md",
  category:
    "font-bold text-xxxs absolute top-2 left-2 bg-white/60 py-1 px-2 rounded-md",
  nameContainer: "text-sm font-bold min-h-50px mb-1 mt-2",
  tagsContainer: "text-xxs flex flex-wrap gap-2 text-gray-700",
  cardTagsContainer: "flex flex-wrap gap-2 mt-2",
  likelyTag:
    "text-xxxs font-semibold text-red-700 bg-red-600/20 px-2 py-1 rounded-md",
  topTag:
    "text-xxxs font-semibold text-yellow-800 bg-yellow-600/20 px-2 py-1 rounded-md w-fit",
  priceContainer: "flex flex-col text-sm text-gray-700 py-4",
};

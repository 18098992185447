import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfirmFlightVoidRequestModal,
  setTicketManagementLoadingModal,
} from "../../redux/reducers/modalSlice";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ConfirmFlightVoidRequestModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { confirmFlightVoidRequestModal } = useSelector((state) => state.modal);
  const { tour } = useSelector((state) => state.tourData);

  const handleOpen = () => dispatch(setConfirmFlightVoidRequestModal(true));
  const handleClose = () => dispatch(setConfirmFlightVoidRequestModal(false));

  const handleConfirm = async () => {
    dispatch(setTicketManagementLoadingModal(true));
    handleClose();
    setTimeout(() => {
      dispatch(setTicketManagementLoadingModal(false));
      navigate("/order/flight/ticket/confirmation?type=void");
    }, 2000);
  };

  return (
    <div>
      <Modal
        open={confirmFlightVoidRequestModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>Confirm void request</span>
              <p className={Classname.modalDescription}>
                Once confirmed, we will go ahead and cancel PNR. Customer will
                not be able to use the ticket anymore.
              </p>
            </div>
            <div className={Classname.bottomContent}>
              <button
                className={`${Classname.button} ${Classname.backButton}`}
                onClick={handleClose}
              >
                Go Back
              </button>
              <button
                className={`${Classname.button} ${Classname.confirmButton}`}
                onClick={handleConfirm}
              >
                Confirm void
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content: "flex flex-col items-center px-4",
  modalTitle: "text-xl lg:text-3xl font-bold mb-6 mt-10 text-center",
  modalDescription:
    "text-gray-600 mb-6 text-center text-sm lg:text-base max-w-md",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};

import React from "react";
import OrderTypeSelection from "../../components/order/OrderTypeSelection";
import OrderFilterTypeSelection from "../../components/order/OrderFilterTypeSelection";
import OrderStatusFilterSelection from "../../components/order/OrderStatusFilterSelection";
import OrderSortHeader from "../../components/order/OrderSortHeader";
import OrderList from "../../components/order/OrderList";
import OrderPagination from "../../components/order/OrderPagination";

function NewOrders() {
  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        <OrderTypeSelection />
        <OrderFilterTypeSelection />
        <OrderStatusFilterSelection />
        <OrderSortHeader />
        <OrderList />
        <OrderPagination />
      </div>
    </div>
  );
}

export default NewOrders;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 pb-96",
};

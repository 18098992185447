import { ChevronLeft } from "@mui/icons-material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const TicketManagementHeader = ({ title }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    if (location.pathname?.includes("detail")) {
      navigate("/order");
    } else {
      navigate("/order/flight/ticket/detail");
    }
  };
  return (
    <div className="flex justify-between items-center mb-4 bg-[#F7F9FA] py-2 shadow-md w-full">
      <div className="flex items-center gap-4">
        <div className="p-2 border-r cursor-pointer" onClick={handleBack}>
          <ChevronLeft />
        </div>
        <h2 className="text-lg font-bold">{title || "Order details"}</h2>
      </div>
      <button
        className="bg-white text-black px-4 py-2 rounded-md border mr-3"
        onClick={handleBack}
      >
        Back
      </button>
    </div>
  );
};

export default TicketManagementHeader;

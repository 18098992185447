import React, { useEffect, useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import SortDropdown from "../../../components/filter/SortDropdown";
import StayFilter from "../../../components/filter/StayFilter";
import HotelCard from "../../../components/accommodation/HotelCard";
import StayFilterMobile from "../../../components/filter/StayFilterMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  setStayFilterActive,
  setStaysFilter,
} from "../../../redux/reducers/tour/filterSlice";
import LocationInput from "../../../components/form/LocationInput";
import { useNavigate } from "react-router-dom";
import { useHotels } from "../../../hooks/useHotels";
import Loader from "../../../utils/loader";
import { URLdecode, URLencode } from "../../../utils/url";
import { useFilter } from "../../../hooks/useFilter";
import useDateFormat from "../../../hooks/useDateFormat";
import { Pagination } from "@mui/material";

function AccommodationResultPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getHotels } = useHotels();
  const { isPastDate, compareDate, formatDate, isSameDate } = useDateFormat();
  const { filterHotels } = useFilter();
  const { stayFilterActive, filterStayData } = useSelector(
    (state) => state.tourFilter
  );
  const { hotels: loadedHotels } = useSelector((state) => state.hotelData);
  const { adultsNo, childrenNo } = useSelector((state) => state.data);

  const params = URLdecode();
  const start_date = params?.start_date;
  const end_date = params?.end_date;
  const queryLocation = params?.location;
  const lng = params?.lng;
  const lat = params?.lat;

  const [startDate, setStartDate] = useState(start_date);
  const [endDate, setEndDate] = useState(end_date);
  const [location, setLocation] = useState();
  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState(false);
  const [sort, setSort] = useState("Recommended");
  const [center, setCenter] = useState({ latitude: lat, longitude: lng });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hotels, setHotels] = useState(loadedHotels);

  const hotelsPerPage = 20;
  const pages =
    parseInt(hotels?.length / hotelsPerPage) +
    (hotels?.length % 20 > 0 ? 1 : 0);

  const calendarRef = useRef([...Array(10)]);

  const loadData = async () => {
    await getHotels(setLoading);
  };

  useEffect(() => {
    loadData();
  }, [start_date, end_date, queryLocation]);

  useEffect(() => {
    if (loadedHotels) {
      setHotels(loadedHotels);
    }
  }, [loadedHotels]);

  useEffect(() => {
    let data = filterHotels();
    data && setHotels(data);
  }, [filterStayData]);

  useEffect(() => {
    setError("");
  }, [center, startDate, endDate]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [hotels]);

  const handleSetDate = (val, index) => {
    if (index === 0) {
      setStartDate(val);
    } else if (index === 1) {
      setEndDate(val);
    }

    if (calendarRef.current?.at(index + 1)) {
      calendarRef.current[index + 1]?.toggle(
        calendarRef.current[index + 1]?.ref?.current
      );
    }
    calendarRef.current[index]?.toggle();
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  const handleSearch = () => {
    if (!center.latitude && !center.longitude)
      return setError("Please select a location");

    if (isPastDate(startDate)) {
      setError("Start date can't be a past date");
      return;
    }
    if (isPastDate(endDate)) {
      setError("End date can't be a past date");
      return;
    }
    if (compareDate(endDate, startDate)) {
      setError("End date can't be a before start date");
      return;
    }

    if (isSameDate(startDate, endDate)) {
      setError("Start data and End date can't be the same");
      return;
    }

    const parameters = {
      ...params,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      adult: adultsNo,
      children: childrenNo,
      lat: center.latitude,
      lng: center.longitude,
      location: location?.label || queryLocation,
    };

    navigate(`/accommodation/results?${URLencode(parameters)}`);
  };

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  const onFilterClick = () => {
    dispatch(setStayFilterActive(true));
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        {/* Page title */}
        <div className={Classname.titleContainer}>
          {`Orders > Accommodations >`}{" "}
          <span className={Classname.titleMain}>New Order</span>
        </div>

        <div className={Classname.cityContainer}>
          <span>{queryLocation}</span>
          {!edit && (
            <button className={Classname.editButton} onClick={handleEdit}>
              Edit
            </button>
          )}
        </div>
        {edit && (
          <div className={Classname.cityEditContainer}>
            <div className="flex-1 w-full">
              <LocationInput
                height={30}
                placeholder={queryLocation}
                setValue={(place) => setLocation(place)}
                setCenter={setCenter}
              />
            </div>
            <div className={Classname.cityEditCountryInput}>
              <CalendarInput1
                ref={(el) => (calendarRef.current[0] = el)}
                className={Classname.cityEditDateInput}
                value={startDate || ""}
                onChange={(value) => handleSetDate(value?.start || value, 0)}
                config={{ validDates: [new Date()] }}
              />
            </div>
            <div className={Classname.cityEditCountryInput}>
              <CalendarInput1
                ref={(el) => (calendarRef.current[1] = el)}
                className={Classname.cityEditDateInput}
                value={endDate || ""}
                onChange={(value) => handleSetDate(value?.start || value, 1)}
                config={{ validDates: [startDate || new Date()] }}
              />
            </div>
            <button className={Classname.searchButton} onClick={handleSearch}>
              Search
            </button>
          </div>
        )}
        {error && <span className="text-red-600 text-sm mt-4">{error}</span>}
        <div className={Classname.dropdownsContainer}>
          <SortDropdown value={sort} onChange={handleSortChange} />
        </div>

        {/* Content */}
        <div className={Classname.resultContent}>
          {/* Filter content */}
          <div className={Classname.resultFilterContainer}>
            <StayFilter />
          </div>
          <StayFilterMobile />
          {/* Tours content */}
          <div className={Classname.resultToursContainer}>
            <Loader loading={loading} />
            {!loading && (
              <div className={Classname.resultActivities}>
                {hotels
                  ?.slice((page - 1) * hotelsPerPage, page * hotelsPerPage)
                  ?.map((hotel, index) => (
                    <HotelCard key={index} hotel={hotel} />
                  ))}
              </div>
            )}
            {hotels?.length > hotelsPerPage && !loading && !loadingMore && (
              <div className={Classname.paginationContainer}>
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  size="medium"
                  page={page}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!stayFilterActive && (
        <div className="flex lg:hidden w-full px-5 justify-around items-center fixed bg-gray-200  bottom-0 rounded-lg shadow-md">
          <div className="flex  space-x-2">
            <img src="/IconSort.svg" alt="" />
            <div className="text-sm font-medium">Sort</div>
          </div>

          <div className="bg-white px-8 py-2 justify-center items-center">
            <div className="">Logos, Nigeria</div>

            <button className="text-sm ml-7 font-medium text-blue-500 hover:text-gray-700">
              Change
            </button>
          </div>

          <div className="flex space-x-2" onClick={onFilterClick}>
            <img src="/IconFilter.svg" alt="" />
            <button className="text-sm font-medium hover:text-gray-700">
              Filter
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccommodationResultPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1",
  titleContainer: "w-full h5 my-10 mb-4",
  titleMain: "text-black",

  cityContainer: "flex items-center gap-2 text-5xl font-black",
  editButton: "bg-primary1 text-sm text-white font-bold py-1 px-3 rounded-md",
  cityEditContainer:
    "flex flex-col lg:flex-row items-center w-full justify-between gap-4 lg:gap-10 mt-6",
  searchButton: "bg-primary1 text-sm text-white font-bold py-1 px-3 rounded-md",
  cityEditCountryInput: "flex-1 w-full",
  cityEditDateInput: "border border-primary/20 rounded-md p-2 flex-1 w-full",

  optionsContainer:
    "w-full flex flex-wrap bg-gray-200 overflow-hidden border border-gray-300",
  option:
    "flex flex-1 px-2 sm:px-10 text-xxxs sm:text-base h-16 items-center justify-center sm:justify-start text-center sm:text-start font-bold border border-gray-300 cursor-pointer min-w-100px sm:min-w-200px ",
  optionLast: "",
  optionActive: "bg-primary1 text-white",

  dropdownsContainer: "flex justify-end items-center gap-4 mt-6",

  resultContent: "flex w-full gap-10 mt-10",
  resultFilterContainer: "hidden lg:flex flex-col w-72 ",
  resultDateContainer: "w-full bg-primary1 pt-6 rounded-t-xl p-4 mb-2",
  resultDateTitle: "text-white font-semibold text-sm",
  resultDateInput: "w-full bg-white border border-white rounded-md p-2 mt-2",

  resultToursContainer: "flex flex-1 mb-20 lg:mb-0 flex-col",
  resutlToursTitle: "text-lg font-bold mb-4",
  resultTopActivities: "flex flex-wrap justify-between mb-10 gap-10",
  resultTopSights: "flex flex-wrap justify-between mb-10 gap-10",
  resultActivities: "flex flex-wrap justify-between gap-10 mb-10",

  paginationContainer: "flex w-full items-center justify-center mb-10",
};

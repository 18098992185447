import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GoogleAPI } from "../../utils/key";

const styles = {
  // Customize styles here
  autocompleteInput: {
    backgroundColor: "#f5f5f5",
    border: "1px solid #ddd",
    borderRadius: 4,
    padding: "10px",
    fontSize: 16,
  },
  autocompleteDropdown: {
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: 4,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginTop: 8,
  },
  dropdownListItem: {
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
};

const LocationInput = ({ value, setValue, setCenter, placeholder, height }) => {
  const handleChange = (suggestion) => {
    setValue(suggestion);
    geocodeByAddress(suggestion.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        setCenter({
          latitude: lat,
          longitude: lng,
        })
      );
  };
  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={GoogleAPI}
        styles={styles}
        selectProps={{
          value,
          onChange: handleChange,
          placeholder,
          styles: {
            input: (provided) => ({
              ...provided,
              height: height || 40,
            }),
          },
        }}
      />
    </div>
  );
};

export default LocationInput;

import { Check } from "@mui/icons-material";
import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementUploadDocument from "../../../components/TicketManagement/TicketManagementUploadDocument";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementAddRemark from "../../../components/TicketManagement/TicketManagementAddRemark";
import TicketManagementFlightRefundStepsHeader from "../../../components/TicketManagement/TicketManagementFlightRefundStepsHeader";
import { useDispatch } from "react-redux";
import { setConfirmFlightSubmitRefundRequestModal } from "../../../redux/reducers/modalSlice";
import ConfirmFlightSubmitRefundRequestModal from "../../../components/modal/ConfirmFlightSubmitRefundRequestModal";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";

function TicketManagementFlightRefund() {
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <ConfirmFlightSubmitRefundRequestModal />
      <TicketManagementLoadingModal />

      <TicketManagementHeader />
      <TicketManagementFlightRefundStepsHeader step={1} />
      <TicketManagementPassengerSelection />
      <SelectReason />
      <TicketManagementUploadDocument />
      <TicketManagementAddRemark />
      <TicketManagementContactDetails />
      <TicketActionButtons />
    </div>
  );
}

const SelectReason = () => {
  const [reason, setReason] = useState("");

  const reasons = [
    "Voluntary",
    "Flight reschedule/ Cancellation",
    "Re-issue due to medical reasons",
    "Re-issue due to visa refusal",
    "Other reasons",
  ];

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">Select a reason for change</span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          There are two types of changes: Voluntary and Involuntary changes.
          Voluntary changes refer to changes that are made in accordance to the
          fare rules set by airlines. These include modifications that are
          allowed within the allowed guideline. Any other change is considered
          involuntary. Involuntary changes require a document to support reason
          for change. The document would serve as an evidence for reasons behind
          the change and help ensure a smooth processing.
        </span>
      </div>
      <div className="max-w-sm">
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reason}
            placeholder="Select a reason"
            onChange={(e) => setReason(e?.target?.value)}
          >
            {reasons.map((r, index) => (
              <MenuItem key={index} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const TicketActionButtons = () => {
  const dispatch = useDispatch();
  const handleCancel = () => {};

  const handleSubmit = () => {
    dispatch(setConfirmFlightSubmitRefundRequestModal(true));
  };
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSubmit}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Submit refund request
      </button>
    </div>
  );
};

export default TicketManagementFlightRefund;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};

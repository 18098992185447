import { useSelector } from "react-redux";
import Logo from "../../../../components/Logo/Logo";
import { getPassengerCategory } from "../../../../utils/getPassengerCategory";
import FlightInfo from "./FlightInfo";
import PassengerInfo from "./PassengerInfo";
import PriceSummary from "./PriceSummary";
import { getCurrencySymbol } from "../../../../features/utils/currency";
import { formatMoney } from "../../../../features/utils/formatMoney";
import moment from "moment";

export default function FlightDoc({data}) {
    const {user} = useSelector(state => state.user.userData);
    const orderData = data?.booking?.flightBooking?.at(0)

    const currency = getCurrencySymbol(
      data?.booking?.flightBooking?.at(0)?.currency,
    )
    return (
      <div className='flex flex-col gap-10 p-0 card' id='flightDoc'>
        <div className='flex flex-col gap-10 p-5'>
          <div className='flex justify-between gap-4'>
            <div className="flex gap-3 items-center">
              <img src={user?.detail?.agencyLogo} alt='' className="w-7 h-7" />
              <h5>{user?.detail?.tradingName}</h5>
            </div>
            {/* <Logo /> */}
            <div>
              Booking Reference
              <div className='text-theme1'>{data?.booking?.bookingId}</div>
            </div>
          </div>

          <div className='bg-primary/10 p-4 rounded-md'>
            Booking status: {orderData?.status} | Your booking expires on : {moment(orderData?.ticketingExpiry)?.format('DD MMM YYYY HH:mm')}
          </div>

          <div className='flex gap-2 flex-col'>
            <h5>Passengers</h5>
            <div className="">
              {
                orderData?.travelers?.map((traveler,i) => (
                  <PassengerInfo key={i} index={i}
                    label={getPassengerCategory(traveler.birthDate)}
                    traveler={traveler}
                    order={data}
                  />
                ))
              }

                {/* {data?.orderDetail?.travelers &&
                  Array.isArray(data?.orderDetail?.travelers) &&
                  data?.orderDetail?.travelers?.map((traveler) => (
                    <PassengerInfo
                      label={getPassengerCategory(traveler.dateOfBirth)}
                      traveler={traveler}
                      order={data}
                    />
                  ))} */}
                {/* <PassengerInfo label={"Adult"} />
                <PassengerInfo label={"Child"} /> */}
              </div>
              {/* <PriceSummary onPDF={true} data={data} /> */}

            {/* <div className='border border-primary/50 p-4'>
              <PassengerInfo />
            </div> */}
          </div>

          <div className='flex gap-4 flex-col'>
            <h5>Flight Details</h5>
            <div className='border border-primary/50 p-4'>
              <FlightInfo data={data} />
            </div>
          </div>

          <div className="flex gap-4 justify-between">
            <h5>Trip Total ({currency}):</h5>
            <h5>{formatMoney(data?.booking?.flightBooking?.at(0)?.customerPayable, currency)}</h5>
          </div>

          
        </div>
  
  
        {/* <div className='flex gap-4 flex-col'>
          <h5>Ticket numbers</h5>
          <div className='border border-primary/50 p-4 flex flex-col gap-4'>
            <span>Daniel Atelly: 1</span>
          </div>
        </div> */}
        
      </div>
    )
  }
  
import React, { useState } from 'react'
import TextInput from '../../../components/form/TextInput'
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import updateProfile from '../../../controllers/user/updateProfile';
import { setUser } from '../../../redux/reducers/userSlice';
import Button1 from '../../../components/form/Button1';
import { MenuItem } from '@mui/material';
import Icon from '../../../components/HOC/Icon';

export default function AgencyLink() {
    const { user } = useSelector((state) => state.user.userData);

    const [data, setData] = useState({
        agencyLogo: user?.detail?.agencyLogo || "",
        agencyName: user?.detail?.agencyName || "",
        // registeredBusinessName: user?.detail?.registeredBusinessName || "",
        agencyURL: user?.detail?.agencyURL || "",
      });
    const [subdomain,setSubdomain] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    async function handleSubmit(ev) {
        ev.preventDefault();

        const formData = new FormData();
        const modData = {...data};
        modData.agencyURL = data.agencyName+'.'+subdomain;
        Object.entries(modData).map(([key, val]) => val && formData.append(key, val));

        setLoading(true);
        const res = await updateProfile(formData);
        setLoading(false);
        if (res.return) {
            enqueueSnackbar("Profile Updated", { variant: "success" });
            // console.log(res.data);
            if (res?.data) dispatch(setUser(res.data));
        } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
        // for (let [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`);
        // }
    }
    
  return (
    <div className='flex flex-col gap-6'>
        <div>
            <h5>Agency Link</h5>
            <p>Personalize communication and collaboration within your team with agency link</p>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className='flex items-start gap-2 flex-wrap'>
            <div className='flex-1'>
              <TextInput
                label={"Choose a link name"}
                inputProps={{
                    pattern: '^[a-zA-Z]+$',
                    title: 'Please use only alphabetic characters'
                }}
                placeholder={"e.g flyaway"}
                value={data.agencyName}
                onChange={(ev) =>
                  setData({ ...data, agencyName: ev.target.value })
                }
                tooltip={"This is a unique identifier for your team. It must contain only URL-friendly characters."}
              />
            </div>
            {/* <div>
              <TextInput
                value={data.agencyURL}
                onChange={(ev) =>
                  setData({ ...data, agencyURL: ev.target.value })
                }
                label={"Agency URL"}
                placeholder={"app.miles.com/agencyname"}
              />
              <div className="tooltip">
                This is a unique identifier for your team. It must contain only
                URL-friendly characters.
              </div>
            </div> */}
            <div className='flex-1 flex gap-2 items-end'>
              <div className=''>
                .
              </div>
              <TextInput select label='Select a subdomain'
                placeholder={'Select from the list'}
                onChange={(ev) => setSubdomain(ev.target.value)}
                >
                <MenuItem value='mytravx.com'>mytravx.com</MenuItem>
                <MenuItem value='travx.agency'>travx.agency</MenuItem>
                <MenuItem value='travx.center'>travx.center</MenuItem>
              </TextInput>
            </div>
          </div>
          <div className="flex sm:justify-end">
            <Button1
                loading={loading}
                type="submit"
                className="!self-end !text-base sm:!w-auto !px-4 !capitalize"
            >
                Save Changes
            </Button1>
            </div>

        </form>
        <div className='relative flex flex-col gap-2 p-4 pl-0 self-start min-w-[250px] border-primary/20'>
            <b>Agency Link</b>
            <div className='flex gap-4 items-center justify-between'>
                {subdomain ? 
                    data.agencyName+'.'+subdomain
                : data.agencyURL}
                <a href={!subdomain ? 'http://'+data.agencyURL : 'http://'+data.agencyName+'.'+subdomain} rel='noreferrer' target='_blank' 
                  className=''>
                    <Icon icon='iconoir:open-new-window' className='p-1 text-blue-700' />
                </a>
            </div>
        </div>
    </div>
  )
}

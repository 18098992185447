import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneNumberInput from "../../../components/form/PhoneNumberInput";
import ConfirmHotelBookingModal from "../../../components/modal/ConfirmHotelBookingModal.jsx";
import { useHotels } from "../../../hooks/useHotels.js";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/loader.js";
import { URLdecode, URLencode } from "../../../utils/url.js";
import { formatMoney } from "../../../features/utils/formatMoney.js";
import { useSnackbar } from "notistack";
import {
  setConfirmHotelBookingModal,
  setConfirmHotelBookingModalPaymentOption,
} from "../../../redux/reducers/modalSlice.js";
import useDateFormat from "../../../hooks/useDateFormat.js";

function AccommodationUserInfoPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { getHotelAvailability, travellersString, bookRooms } = useHotels();
  const { calculateAge } = useDateFormat();
  const { hotelAvailability } = useSelector((state) => state.hotelData);
  const params = URLdecode();
  const [date, setDate] = useState("");
  const [language, setLanguage] = useState(null);
  const [people, setPeople] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [rooms, setRooms] = useState();
  const [personType, setPersonType] = useState("adult");
  const [payOption, setPayOption] = useState("now");
  const [modalOpen, setModalOpen] = useState(false);
  const [hotelLoading, setHotelLoading] = useState(false);

  const loadData = async () => {
    await getHotelAvailability(setHotelLoading);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (JSON.parse(params.reservedRooms)) {
      setRooms(JSON.parse(params.reservedRooms));
    }
  }, [params.reservedRoom]);

  const hotelImage = () => {
    let image = "";

    if (hotelAvailability) {
      if (hotelAvailability?.details?.image) {
        image = hotelAvailability?.details?.image[0];
      }
    }

    return image;
  };

  const numberOfNights = () => {
    if (
      !hotelAvailability ||
      !hotelAvailability?.start_date ||
      !hotelAvailability?.end_date
    ) {
      return "";
    }

    const startDate = new Date(hotelAvailability.start_date);
    const endDate = new Date(hotelAvailability.end_date);

    const diffTime = Math.abs(endDate - startDate);

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const nightsLabel = diffDays === 1 ? "night" : "nights";

    return `${diffDays} ${nightsLabel}`;
  };

  const reservedRooms = () => {
    let rooms = [];
    const params = URLdecode();
    if (params?.reservedRooms) {
      let rRooms = JSON.parse(params?.reservedRooms);
      rooms = rRooms;
    }

    return rooms;
  };

  const totalAmount = () => {
    let total = 0;
    const params = URLdecode();
    if (params?.reservedRooms) {
      let rRooms = JSON.parse(params?.reservedRooms);
      rRooms?.map((room) => {
        total += room?.amount * room?.price;
      });
    }

    return formatMoney(total);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePayOptionChoose = (text) => {
    setPayOption(text);
    dispatch(setConfirmHotelBookingModalPaymentOption(text));
  };

  const InputNotValid = () => {
    let notValid = false;
    if (!email) {
      enqueueSnackbar("Email is required", { variant: "warning" });
      notValid = true;
      // return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      enqueueSnackbar("Invalid email address", { variant: "warning" });
      notValid = true;
      // return;
    }
    if (!phone) {
      enqueueSnackbar("Phone number is required", { variant: "warning" });
      notValid = true;
      // return;
    }
    if (!phone?.split("-")[1]) {
      enqueueSnackbar("Phone number is required", { variant: "warning" });
      notValid = true;
      // return;
    }
    let rRooms = rooms ? [...rooms] : [];
    rRooms.map((room) => {
      let guests = room?.guests;
      let guest = guests[0];
      if (!guest?.title) {
        enqueueSnackbar("Title is required for guests", {
          variant: "warning",
        });
        notValid = true;
        // return;
      }
      if (!guest?.firstName) {
        enqueueSnackbar("First name is required for guests", {
          variant: "warning",
        });
        notValid = true;
        // return;
      }
      if (!guest?.lastName) {
        enqueueSnackbar("Last name is required for guests", {
          variant: "warning",
        });
        notValid = true;
        // return;
      }
    });
    return notValid;
  };

  const handleBook = () => {
    if (InputNotValid()) return;
    let rRooms = rooms ? [...rooms] : [];
    rRooms.map((room) => {
      let reservedRoom = room;
      if (reservedRoom) {
        let guests = reservedRoom?.guests;
        let guest = guests[0];
        guest = { ...guest, email: email, phoneNumber: phone };
        guests[0] = guest;
        reservedRoom = { ...reservedRoom, guests };
        room = reservedRoom;
      }
      return room;
    });
    const parameters = {
      ...params,
      reservedRooms: JSON.stringify(rooms),
      contactEmail: email,
      payOption: payOption,
    };
    navigate(`/accommodation/userinfo?${URLencode(parameters)}`);
    dispatch(setConfirmHotelBookingModal(true));
  };

  const handleChangeRoomGuestTitle = (index, name) => {
    let reservedRooms1 = rooms ? [...rooms] : [];
    let reservedRoom = reservedRooms1[index];
    if (reservedRoom) {
      let guests = reservedRoom?.guests;
      let guest = guests[0];
      guest = { ...guest, title: name };
      guests[0] = guest;
      reservedRoom = { ...reservedRoom, guests };
      reservedRooms1[index] = reservedRoom;
    }
    setRooms(reservedRooms1);
  };

  const handleChangeRoomGuestFirstName = (index, name) => {
    let reservedRooms = rooms ? [...rooms] : [];
    let reservedRoom = reservedRooms[index];
    if (reservedRoom) {
      let guests = reservedRoom?.guests;
      let guest = guests[0];
      guest = { ...guest, firstName: name };
      guests[0] = guest;
      reservedRoom = { ...reservedRoom, guests };
      reservedRooms[index] = reservedRoom;
    }
    setRooms(reservedRooms);
  };

  const handleChangeRoomGuestLastName = (index, name) => {
    let reservedRooms = rooms ? [...rooms] : [];
    let reservedRoom = reservedRooms[index];
    if (reservedRoom) {
      let guests = reservedRoom?.guests;
      let guest = guests[0];
      guest = { ...guest, lastName: name };
      guests[0] = guest;
      reservedRoom = { ...reservedRoom, guests };
      reservedRooms[index] = reservedRoom;
    }
    setRooms(reservedRooms);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const travellerText = (index) => {
    let text = "";

    let tempRooms = rooms ? [...rooms] : [];
    let tempRoom = tempRooms[index];

    if (!tempRoom) {
      return text;
    }

    let guests = tempRoom.guests;

    let children = guests?.filter((guest) => guest.type === "child");
    let adults = guests?.filter((guest) => guest.type === "adult").length;

    if (adults > 0) {
      text += `${adults} Adult${adults > 1 ? "s" : ""}`;
    }

    if (children && children.length > 0) {
      text += `, ${children.length} Children (`;
      text += children
        .map((child) => `${calculateAge(child?.birthdate)} year old`)
        .join(", ");
      text += ")";
    }

    return text;
  };

  function formatMonthDate(dateString) {
    const date = new Date(dateString);

    const options = { month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const cancellationPolicy = () => {
    let policies = [];
    let reservedRooms = params?.reservedRooms
      ? JSON.parse(params?.reservedRooms)
      : null;
    if (reservedRooms) {
      reservedRooms?.slice(0, 1).map((room, index) => {
        policies.push({
          title: "Cancellation Policy",
          detail: `Fully refundable till ${formatMonthDate(
            room?.cancelRefundable
          )}`,
        });
      });
    }

    return policies;
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        {/* Page title */}
        <div className={Classname.titleContainer}>
          {`Stays > Orders > New Order >`}{" "}
          <span className={Classname.titleMain}>Single Stay</span>
        </div>
        <ConfirmHotelBookingModal />
        {/* Detail container */}
        <div className={Classname.detailContainer}>
          {/* Detail */}
          <div className={Classname.detailContent}>
            <div className={Classname.detailPrivacyContainer}>
              <img
                src="/IconLock.svg"
                alt=""
                className={Classname.detailPrivacyIcon}
              />
              <span>
                We take privacy issues seriously. You can be sure that your
                personal data is securely protected.
              </span>
            </div>

            <div className={Classname.detailTitleContainer}>
              <span className={Classname.detailTitle}>Contact details</span>
            </div>

            <div className={Classname.detailInputsContainer}>
              <div className={Classname.detailInputContainer}>
                <span className={Classname.detailInputLabel}>
                  Enter order email*
                </span>
                <div className={Classname.detailInput}>
                  <input
                    type="text"
                    value={email}
                    onChange={handleChangeEmail}
                    className={Classname.detailInputBar}
                    placeholder="e.g xyz@gmail.coom"
                  />
                </div>
              </div>
              <div className={Classname.detailInputContainer}>
                <span className={Classname.detailInputLabel}>
                  Enter order phone number*
                </span>
                <PhoneNumberInput
                  className={Classname.detailPhoneInput}
                  value={phone}
                  onChange={(value) => setPhone(value)}
                />
              </div>
            </div>

            <div className={Classname.detailInfoContainer}>
              <img
                src="/IconInfo.svg"
                alt=""
                className={Classname.detailInfoIcon}
              />
              <span>
                Use all given names and surnames exactly as they appear in your
                passport/ID to avoid boarding complications
              </span>
            </div>

            {/* Guest one */}
            {rooms?.map((room, index) => (
              <div className="flex flex-1 flex-col" key={index}>
                <div className={Classname.detailTitleContainer}>
                  <span className={Classname.detailTitle}>
                    Room {index + 1}
                  </span>
                </div>
                <div className={Classname.detailTitleContainer}>
                  <span className={Classname.detailTitle}>
                    Guests: {travellerText(index)}
                  </span>
                </div>

                <div className={Classname.detailInputsContainer}>
                  <div className={"flex flex-col"}>
                    <span className={Classname.detailInputLabel}>Title*</span>
                    <FormControl className="w-32">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="h-12"
                        value={room?.guests[0]?.title}
                        onChange={(e) =>
                          handleChangeRoomGuestTitle(index, e.target.value)
                        }
                      >
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={Classname.detailInputContainer}>
                    <span className={Classname.detailInputLabel}>
                      Given names*
                    </span>
                    <div className={Classname.detailInput}>
                      <input
                        type="text"
                        className={Classname.detailInputBar}
                        placeholder="e.g John Chiemena"
                        value={room?.guests[0]?.firstName}
                        onChange={(e) =>
                          handleChangeRoomGuestFirstName(index, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className={Classname.detailInputContainer}>
                    <span className={Classname.detailInputLabel}>Surname*</span>
                    <div className={Classname.detailInput}>
                      <input
                        type="text"
                        className={Classname.detailInputBar}
                        placeholder="e.g Doe"
                        value={room?.guests[0]?.lastName}
                        onChange={(e) =>
                          handleChangeRoomGuestLastName(index, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                {room?.rateComments && (
                  <div className={Classname.detailInfoContainer}>
                    <img
                      src="/IconInfo.svg"
                      alt=""
                      className={Classname.detailInfoIcon}
                    />
                    <span>{room?.rateComments}</span>
                  </div>
                )}
              </div>
            ))}

            {/* <div className={Classname.detailTitleContainer1}>
              <span className={Classname.detailTitle}>
                Special requirements
              </span>
            </div>
            <textarea
              className={Classname.detailTextArea}
              placeholder="e.g. dietary needs, accessibility"
              name=""
              id=""
              cols="30"
              rows="10"
            /> */}
            <div className={Classname.detailTitleContainer1}>
              <span className={Classname.detailTitle}>
                Paying now, or later?
              </span>
            </div>
            {/* <p className={Classname.detailDescription}>
              Decide whether you want to pay for your trip now in its entirety,
              or whether you'd like to put a hold on the order, and pay at a
              later date. Be aware that you cannot currently select seats or
              baggage when holding an order.
            </p> */}
            <div className={Classname.detailPayOptionsContainer}>
              <div
                className={`${Classname.detailPayOption} ${
                  payOption === "now"
                    ? Classname.detailPayOptionActive
                    : Classname.detailPayOptionInActive
                }`}
                onClick={() => handlePayOptionChoose("now")}
              >
                <div className={Classname.detailPayOptionIconContainer}>
                  {payOption === "now" ? (
                    <img
                      src="/IconCheckmark3.svg"
                      alt=""
                      className={Classname.detailPayOptionIcon}
                    />
                  ) : (
                    <div
                      className={Classname.detailPayOptionIconInActive}
                    ></div>
                  )}
                </div>
                <div className={Classname.detailPayOptionDetail}>
                  <span className={Classname.detailPayOptionTitle}>
                    Pay now
                  </span>
                  <p className={Classname.detailPayOptionDescription}>
                    Pay now and confirm seat and baggage selection.
                  </p>
                </div>
              </div>
              <div
                className={`${Classname.detailPayOption} ${
                  payOption === "later"
                    ? Classname.detailPayOptionActive
                    : Classname.detailPayOptionInActive
                }`}
                onClick={() => handlePayOptionChoose("later")}
              >
                <div className={Classname.detailPayOptionIconContainer}>
                  {payOption === "later" ? (
                    <img
                      src="/IconCheckmark3.svg"
                      alt=""
                      className={Classname.detailPayOptionIcon}
                    />
                  ) : (
                    <div
                      className={Classname.detailPayOptionIconInActive}
                    ></div>
                  )}
                </div>
                <div className={Classname.detailPayOptionDetail}>
                  <span className={Classname.detailPayOptionTitle}>
                    Book on hold
                  </span>
                  <p className={Classname.detailPayOptionDescription}>
                    Hold price and pay at a later date.
                  </p>
                </div>
              </div>
            </div>
            <div className={Classname.detailButtonsContainer}>
              <button
                className={Classname.detailBackButton}
                onClick={handleBack}
              >
                Back
              </button>
              <button
                className={Classname.detailContinueButton}
                onClick={handleBook}
              >
                Continue
              </button>
            </div>
          </div>

          {/* CTA container */}
          <div className={Classname.detailCTAContainer}>
            <Loader loading={hotelLoading} />
            {!hotelLoading && hotelAvailability && (
              <>
                <div className={Classname.detailCTANameContainer}>
                  {hotelImage() && (
                    <img
                      src={hotelImage()}
                      alt=""
                      className={Classname.detailCTAImage}
                    />
                  )}
                  <span className={Classname.mainDetailName}>
                    {hotelAvailability?.name}
                  </span>
                </div>
                <div className={Classname.mainDetailPoint}>
                  <img
                    src="/tourIconLocation.svg"
                    alt=""
                    className={Classname.mainDetailIcon}
                  />
                  <span>{hotelAvailability?.address}</span>
                </div>
                <div className={Classname.mainDetailPoint}>
                  <img
                    src="/tourIconPerson.svg"
                    alt=""
                    className={Classname.mainDetailIcon}
                  />
                  <span>{travellersString()}</span>
                </div>
                <div className={Classname.mainDetailPoint}>
                  <img
                    src="/tourIconDate.svg"
                    alt=""
                    className={Classname.mainDetailIcon}
                  />
                  <span>{numberOfNights()}</span>
                </div>
                {/* <div className={Classname.mainDetailPoint}>
                  <img
                    src="/tourIconTime.svg"
                    alt=""
                    className={Classname.mainDetailIcon}
                  />
                  <span>Sign-in Time: Sat 22 Jul, 2023 from 10:45 AM</span>
                </div>
                <div className={Classname.mainDetailPoint}>
                  <img
                    src="/tourIconTime.svg"
                    alt=""
                    className={Classname.mainDetailIcon}
                  />
                  <span>Sign-out Time: Sat 22 Jul, 2023 from 10:45 AM</span>
                </div> */}
              </>
            )}
            <div className={Classname.detailCTAPriceTitleContainer}>
              <span className={Classname.detailCTAPriceTitle}>
                Price Summary
              </span>
            </div>
            {reservedRooms()?.map((room, index) => (
              <div className={Classname.detailCTADetailContainer}>
                <span>
                  {room?.name}({room?.amount})
                </span>
                <span>{formatMoney(room?.amount * room?.price)}</span>
              </div>
            ))}
            {/* <div className={Classname.detailCTADetailContainer}>
              <span>Taxes and fees:</span>
              <span>₦100,000.00</span>
            </div> */}
            <div className={Classname.detailCTATotalContainer}>
              <span>Tour total:</span>
              <span>{totalAmount()}</span>
            </div>
            {cancellationPolicy()?.map((policy, index) => (
              <div className={Classname.detailCTABenefit}>
                <span className={Classname.detailCTABenefitTitle}>
                  {policy?.title}
                </span>
                <div className={Classname.detailCTABenefitDescription}>
                  <img
                    src="/IconCheckmark1.svg"
                    alt=""
                    className={Classname.detailCTABenefitIcon}
                  />
                  <span>{policy?.detail}</span>
                </div>
              </div>
            ))}
            <div className="hidden lg:flex flex-col">
              <button
                className={Classname.detailCTAButton}
                onClick={handleBook}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccommodationUserInfoPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 ",
  titleContainer: "h5 my-10",
  titleMain: "text-black",

  imagesContainer: "flex flex-wrap w-full gap-6 mb-10",
  mainImageContainer: "relative h-470px flex-1 max-w-xs",
  mainImage: "rounded-xl w-full h-full object-cover",
  mainImageCategory:
    "font-bold text-base absolute top-2 left-2 bg-white/70 py-1 px-2 rounded-md",
  mainImageCTA:
    "font-bold text-xxxs absolute bottom-2 right-2 bg-white/70 py-1 px-2 rounded-md cursor-pointer",
  mainDetailContainer: "flex flex-col flex-1",
  mainDetailName: "text-xl font-bold",
  mainDetailIcon: "",
  mainDetailPoint: "flex items-center gap-2 text-gray-500 mb-2",

  detailContainer: "flex flex-col-reverse lg:flex-row w-full gap-6 mt-6",
  detailContent: "flex flex-col flex-1",
  detailTitleContainer: "flex w-full items-center justify-between mt-2 mb-4",
  detailTitleContainer1: "flex w-full items-center justify-between mt-4 mb-3",
  detailTitle: "text-xl font-bold",
  detailDescription: "mb-2",

  detailPrivacyContainer:
    "w-full bg-blue-500/10 flex items-center gap-2 mb-4 px-6 py-2 rounded-md text-sm",
  detailPrivacyIcon: "",

  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-center gap-2 mb-4 px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",

  detailInputsContainer: "flex justify-between gap-4 flex-wrap",
  detailInputContainer: "flex flex-col flex-1",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
  detailPhoneInput: "mt-2",
  detailPayOptionsContainer:
    "flex flex-col lg:flex-row flex-wrap flex-1 gap-4 mt-6 mb-10",
  detailPayOption:
    "flex flex-1 flex-shrink-0 min-w-56 h-24 rounded-lg gap-3 py-2 px-3 cursor-pointer",
  detailPayOptionActive:
    "border-2 border-green-700 bg-green-700/20 text-green-700",
  detailPayOptionInActive: "bg-gray-100",
  detailPayOptionIconContainer: "",
  detailPayOptionIcon: "w-5 h-5 object-contain",
  detailPayOptionIconInActive: "w-5 h-5 rounded-full bg-gray-200",
  detailPayOptionDetail: "flex flex-col gap-1",
  detailPayOptionTitle: "font-bold",
  detailPayOptionDescription: "",
  detailButtonsContainer:
    "flex gap-2 w-full item-center justify-between mt-10 mb-20",
  detailBackButton: "h-12 w-52 rounded-md text-white font-bold bg-gray-400",
  detailContinueButton: "h-12 w-52 rounded-md text-white font-bold bg-primary1",

  detailCTAContainer:
    "flex flex-col flex-1 lg:max-w-md border-2 border-gray-300 p-2 sm:p-4 h-fit rounded-lg",
  detailCTANameContainer:
    "flex flex-col lg:flex-row lg:items-center gap-4 mb-3",
  detailCTAImage: "w-full lg:w-24 h-56 sm:h-96 lg:h-20 rounded-lg object-cover",
  detailCTATitleContainer:
    "flex items-center justify-between text-sm text-gray-600 mt-6",
  detailCTATitleProvider: "text-lg font-bold mt-4 text-black",

  detailCTABenefit:
    "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2",
  detailCTABenefitTitle: "text-lg font-bold mb-1",
  detailCTABenefitDescription: "flex items-center gap-2 text-sm text-gray-600",
  detailCTABenefitIcon: "",
  detailCTAPriceTitleContainer:
    "flex w-full justify-between text-sm pb-2 border-b-2 border-b-400 mb-4 mt-4",
  detailCTAPriceTitle: "font-bold text-lg",
  detailCTATotalContainer:
    "font-bold text-lg flex w-full justify-between pb-2 pt-3 border-t-2 border-b-400 mb-4 mt-4",
  detailCTADetailContainer: "text-lg flex w-full justify-between text-gray-700",
  detailCTAButton:
    "flex w-full items-center justify-center bg-primary1 rounded-lg h-12 font-bold text-white mt-4",
};

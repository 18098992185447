import React, { useEffect, useState } from "react";
// import CustomButton from "../components/CustomButton";
import RoomCardButton from "../button/RoomCardButton";
import CarouselButtons from "../button/CarouselButtons";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../features/utils/formatMoney";
import { useDispatch, useSelector } from "react-redux";
import { URLdecode } from "../../utils/url";
import { useSnackbar } from "notistack";
import { setReservedRooms } from "../../redux/reducers/tour/dataSlice";
import { useHotels } from "../../hooks/useHotels";
import useDateFormat from "../../hooks/useDateFormat";

const RoomCard = ({ room, index: loadedIndex }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkRoom } = useHotels();
  const { calculateBirthDate } = useDateFormat();
  const { enqueueSnackbar } = useSnackbar();
  const { hotelAvailability } = useSelector((state) => state.hotelData);
  const { reservedRooms } = useSelector((state) => state.data);
  const [selectedImage, setSelectedImage] = useState(0);
  const [amount, setAmount] = useState(1);
  const params = URLdecode();

  const [showDetails, setShowDetails] = useState(false);

  const handleDetailsToggle = () => {
    setShowDetails(!showDetails);
  };

  const handleNextImage = () => {
    let hotelImages = hotelAvailability?.details?.image;
    if (hotelImages) {
      let totalImages = hotelImages?.length;
      if (selectedImage < totalImages - 1) {
        setSelectedImage(selectedImage + 1);
      } else {
        setSelectedImage(0);
      }
    }
  };

  const handlePreviousImage = () => {
    let hotelImages = hotelAvailability?.details?.image;
    if (hotelImages) {
      let totalImages = hotelImages?.length;
      if (selectedImage > 0) {
        setSelectedImage(selectedImage - 1);
      } else {
        setSelectedImage(totalImages - 1 || 0);
      }
    }
  };

  const mainImage = () => {
    let image = "";
    let hotelImages = hotelAvailability?.details?.image;
    if (room?.image) return room?.image;
    if (hotelImages && hotelImages?.length > 0) {
      image = hotelImages[selectedImage];
    }
    return image;
  };

  function formatMonthDate(dateString) {
    const date = new Date(dateString);

    const options = { month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const roomIsReserved = () => {
    let reserved = null;
    let rooms = reservedRooms ? [...reservedRooms] : [];
    if (rooms?.length > 0) {
      let reservedRoom = rooms?.find((rm) => rm?.index === loadedIndex);
      if (reservedRoom) {
        reserved = reservedRoom;
      }
    }

    return reserved;
  };

  const handleReserveRoom = () => {
    let rooms = reservedRooms ? [...reservedRooms] : [];
    let amountOfReservedRooms = 0;
    let amountOfRequestedRooms = JSON.parse(params?.rooms)?.length;
    let requestedRooms = params?.rooms ? [...JSON.parse(params?.rooms)] : [];
    let savedtravelerIndex = 0;
    let savedIndexes = [];
    let requestedRoomIndexes = [];
    let children = [];

    rooms.map((rm) => {
      let amountOfAdult = rm?.guests?.filter(
        (guest) => guest?.type === "adult"
      )?.length;
      let amountOfChildren = rm?.guests?.filter(
        (guest) => guest?.type === "child"
      )?.length;
      if (
        amountOfAdult === room?.guests?.adult &&
        amountOfChildren === room?.guests?.child
      ) {
        savedIndexes.push(rm?.savedtravelerIndex);
      }
    });

    requestedRooms = requestedRooms.filter((rm, index) => {
      let noChildInRm = rm?.children?.length || 0;
      let noChildInRoom = room.guests?.child || 0;
      if (rm?.adults === room.guests?.adult && noChildInRm === noChildInRoom) {
        return true;
      } else return false;
    });

    requestedRooms.map((rm, index) => {
      requestedRoomIndexes.push(index);
    });

    requestedRoomIndexes = requestedRoomIndexes?.filter((index) => {
      let isIndexSaved = savedIndexes.find((saved) => saved === index);
      if (isIndexSaved !== undefined) return false;
      return true;
    });

    rooms?.map((rm) => {
      if (rm?.amount) {
        amountOfReservedRooms += rm?.amount;
      }
    });

    let availableRoomsToBook = amountOfRequestedRooms - amountOfReservedRooms;

    if (amount <= availableRoomsToBook) {
      let guests = [];
      if (room.guests) {
        if (requestedRoomIndexes?.length === 0)
          return enqueueSnackbar(
            `You can only select ${requestedRooms.length} rooms of this type`,
            { variant: "warning" }
          );

        let roomToBeSaved = requestedRoomIndexes[0];
        let requestedRoomGuests = requestedRooms[roomToBeSaved];
        children = requestedRoomGuests?.children;
        savedtravelerIndex = roomToBeSaved;

        if (room?.guests?.adult) {
          for (let i = 0; i < room?.guests?.adult; i++) {
            let first = i === 0;
            guests.push({
              firstName: first ? "" : `Guest ${i + 1}`,
              lastName: first ? "" : `Guest ${i + 1}`,
              title: first ? "" : `Guest ${i + 1}`,
              type: "adult",
              phoneNumber: first ? "" : `Guest ${i + 1}`,
              number: 1,
            });
          }
        }
        if (room?.guests?.child) {
          for (let i = 0; i < room?.guests?.child; i++) {
            guests.push({
              firstName: `child ${i + 1}`,
              lastName: `child ${i + 1}`,
              title: `child ${i + 1}`,
              type: "child",
              phoneNumber: `child ${i + 1}`,
              birthdate: calculateBirthDate(children[i]),
              number: 1,
            });
          }
        }
      } else {
        guests = [
          {
            firstName: "",
            lastName: "",
            title: "",
            type: "adult",
            phoneNumber: "",
            number: 1,
          },
        ];
      }

      rooms = [
        ...rooms,
        {
          index: loadedIndex,
          savedtravelerIndex,
          bookingCode: room?.bookingCode,
          price: room?.farePrice,
          amount,
          name: room?.name,
          availabilityStatus: room?.availabilityStatus,
          type: room?.type,
          typeCode: room?.typeCode,
          ratePlanCode: room?.ratePlanCode,
          meals: room?.meals,
          bookingCode: room?.bookingCode,
          units: room?.units,
          ratePlanCategory: room?.ratePlanCategory,
          paymentType: room?.paymentType,
          nights: room?.nights,
          originalPrice: room?.originalPrice,
          originalCurrency: room?.originalCurrency,
          price: room?.price,
          currency: room?.currency,
          cancelRefundable: room?.cancelRefundable,
          EquivalentPriceUSD: room?.EquivalentPriceUSD,
          nonIncludedTax: room?.nonIncludedTax,
          nonIncludedTaxDescriptions: room?.nonIncludedTaxDescriptions,
          name: room?.name,
          description: room?.description,
          farePrice: room?.farePrice,
          rateComments: room?.rateComments,
          guests: guests,
        },
      ];

      dispatch(setReservedRooms(rooms));
    } else {
      enqueueSnackbar(
        `You requested and can only book ${amountOfRequestedRooms} rooms.`,
        { variant: "warning" }
      );
    }
  };

  const handleRemoveRoom = () => {
    if (roomIsReserved()) {
      roomIsReserved()?.amount && setAmount(roomIsReserved()?.amount);
      let rooms = reservedRooms ? [...reservedRooms] : [];
      let filteredRooms = rooms?.filter((rm) => rm?.index !== loadedIndex);
      dispatch(setReservedRooms(filteredRooms));
    }
  };

  const handleAddRoom = () => {
    if (!roomIsReserved()) {
      setAmount(amount + 1);
    }
  };

  const handleSubtractRoom = () => {
    if (!roomIsReserved()) {
      if (amount > 1) {
        setAmount(amount - 1);
      }
    }
  };

  const showExtra = () => {
    if (room?.rateComments) {
      return true;
    } else return false;
  };

  return (
    <div
      className={`w-80 border-2 font-main text-sm flex flex-shrink-0 flex-col ${
        roomIsReserved() && "border-green-400"
      } rounded-md`}
    >
      <div className="relative h-48">
        <img src={mainImage()} className="w-full h-full object-cover" />
        <CarouselButtons
          next={handleNextImage}
          previous={handlePreviousImage}
        />
      </div>
      <div className="p-2 text-md flex flex-1 flex-col">
        <div className="gap-2 flex flex-col">
          <h2 className="text-sm font-black">{room?.name}</h2>
          <p className="text-gray-400 text-xs">9.2/10 Wonderful!</p>
          <div className="flex gap-2">
            <img src="/IconFt.svg" alt="" />
            <p>411sq ft</p>
          </div>
          <div className="flex gap-2">
            <img src="/IconPeople.svg" alt="" />
            <p>Sleeps two (2)</p>
          </div>
          <div className="flex gap-2">
            <img src="/IconBed.svg" alt="" />
            <p>One (1) king size bed</p>
          </div>
        </div>
        {showDetails && (
          <div className="mt-2">
            <p className="text-gray-600">Additional room features include...</p>
          </div>
        )}
        <button
          onClick={handleDetailsToggle}
          className="text-blue-500 hover:text-blue-700 mt-2 underline"
        >
          {showDetails ? "Less details" : "More details"}
        </button>

        {(room?.cancellationDeadline || room?.cancelRefundable) && (
          <>
            <div className="border-t border-gray-300 my-3"></div>

            <h2 className="text-sm font-bold">Cancellation Policy</h2>
            <p className="text-gray-600">More details on all policies</p>
          </>
        )}

        <div id="group1">
          {room?.cancelRefundable === false && (
            <RoomCardButton
              text="Nonrefundable"
              // value="₦ +0"
              isSelected={true}
              // onClick={handleButtonClickGroup1}
            />
          )}
          {room?.cancellationDeadline && (
            <RoomCardButton
              text={`Fully refundable till ${formatMonthDate(
                room?.cancellationDeadline
              )}`}
              text2="Reserve now, Pay later"
              isSelected={true}
              // onClick={handleButtonClickGroup1}
            />
          )}
          {room?.cancelRefundable && (
            <RoomCardButton
              text={`Fully refundable till ${formatMonthDate(
                room?.cancelRefundable
              )}`}
              text2="Reserve now, Pay later"
              isSelected={true}
              // onClick={handleButtonClickGroup1}
            />
          )}
        </div>

        {showExtra() && <div className="border-t border-gray-300 my-3"></div>}
        {showExtra() && <h2 className="text-sm font-bold">Extras</h2>}
        {room?.rateComments && (
          <RoomCardButton
            text={room?.rateComments}
            isSelected={true}
            // onClick={handleButtonClickGroup1}
          />
        )}
        {room?.promotions && (
          <RoomCardButton
            text={room?.promotions}
            isSelected={true}
            // onClick={handleButtonClickGroup1}
          />
        )}

        {/* <div id="group2">
          <RoomCardButton
            text="No Extras"
            value="₦ +0"
            isSelected={selectedGroup2Value === "₦ +0"}
            onClick={handleButtonClickGroup2}
          />
          <RoomCardButton
            text="Breakfast"
            value="₦ +12"
            isSelected={selectedGroup2Value === "₦ +12"}
            onClick={handleButtonClickGroup2}
          />
        </div> */}

        <div className="border-t border-gray-300 my-3" />
        <div className="flex flex-1 flex-col"></div>

        <h2 className="text-sm font-bold">Pricing</h2>
        <div className="pr-4 mt-2">
          <div className="flex items-center justify-between">
            <div className="text-xs">One room</div>
            <div className="font-bold text-lg">
              {formatMoney(room?.farePrice || room?.price)}
            </div>
          </div>
          <div className="flex items-center mt-2 justify-between">
            <div className="text-xs">Total</div>
            <div className="font-bold text-lg">
              {formatMoney(
                (room?.farePrice || room?.price) *
                  (roomIsReserved()?.amount || amount)
              )}
            </div>
          </div>
        </div>
        {room?.includedTax ? (
          <div className="text-xs mt-3 text-right mr-4">
            Included tax: {formatMoney(room?.includedTax)}
          </div>
        ) : (
          <></>
        )}
        <div className="text-xs mt-3 text-right mr-4 mb-4">
          {room?.nonIncludedTax
            ? "Non included tax: " + formatMoney(room?.nonIncludedTax)
            : "Tax not included"}
        </div>
        {/* <div
          className={`flex items-center justify-center ${
            roomIsReserved() && "opacity-30"
          }`}
        >
          <div className="flex w-40 h-10 rounded-lg border border-primary1 mb-4 justify-between items-center cursor-pointer">
            <div
              className="text-lg font-bold px-4 "
              onClick={handleSubtractRoom}
            >
              -
            </div>
            <div className="flex flex-1 items-center justify-center font-bold text-primary1">
              {roomIsReserved()?.amount || amount}
            </div>
            <div className="text-lg font-bold px-4 " onClick={handleAddRoom}>
              +
            </div>
          </div>
        </div> */}

        {roomIsReserved() ? (
          <div className="bg-red-600 text-center pt-2 text-white rounded-lg h-12">
            <div
              className="text-white font-bold text-lg my-auto cursor-pointer"
              onClick={handleRemoveRoom}
            >
              Remove room
              {roomIsReserved()?.amount !== 1 && "s"}
            </div>
          </div>
        ) : (
          <div className="bg-blue-600 text-center pt-2 text-white rounded-lg h-12">
            <div
              className="text-white font-bold text-lg my-auto cursor-pointer"
              onClick={handleReserveRoom}
            >
              Add room{amount !== 1 && "s"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoomCard;

import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedTicketsSortHeader,
  setTicketFilterType,
  setTicketsSortHeader,
  setTicketStatusType,
  setTicketType,
} from "../redux/reducers/orderSlice";

export default function useOrders() {
  const dispatch = useDispatch();
  const { ticketsSortHeader, selectedTicketsSortHeader } = useSelector(
    (state) => state.order
  );

  const getOrdersCount = () => {
    return {
      flights: 120,
      stays: 24,
      tour: 37,
    };
  };

  const selectOrderType = (orderType) => {
    dispatch(setTicketType(orderType));
  };

  const ticketFilterTypes = [
    {
      name: "All orders",
      onClick: () => dispatch(setTicketFilterType("All orders")),
    },
    {
      name: "Tickets",
      onClick: () => dispatch(setTicketFilterType("Tickets")),
    },
    {
      name: "Re-issue Management",
      onClick: () => dispatch(setTicketFilterType("Re-issue Management")),
    },
    {
      name: "Refund Management",
      onClick: () => dispatch(setTicketFilterType("Refund Management")),
    },
    {
      name: "Void Management",
      onClick: () => dispatch(setTicketFilterType("Void Management")),
    },
    {
      name: "Completed",
      onClick: () => dispatch(setTicketFilterType("Completed")),
    },
  ];

  const ticketStatusFilterTypes = [
    {
      name: "All",
      onClick: () => dispatch(setTicketStatusType("All")),
    },
    {
      name: "Pending Payment",
      onClick: () => dispatch(setTicketStatusType("Pending Payment")),
    },
    {
      name: "Paid",
      onClick: () => dispatch(setTicketStatusType("Paid")),
    },
    {
      name: "Expired",
      onClick: () => dispatch(setTicketStatusType("Expired")),
    },
    {
      name: "Cancelled",
      onClick: () => dispatch(setTicketStatusType("Cancelled")),
    },
    {
      name: "Ticketing",
      onClick: () => dispatch(setTicketStatusType("Ticketing")),
    },
    {
      name: "Re-issue",
      onClick: () => dispatch(setTicketStatusType("Re-issue")),
    },
    {
      name: "Refund",
      onClick: () => dispatch(setTicketStatusType("Refund")),
    },
    {
      name: "Void",
      onClick: () => dispatch(setTicketStatusType("Void")),
    },
    {
      name: "Completed",
      onClick: () => dispatch(setTicketStatusType("Completed")),
    },
  ];

  const ticketSortHeaderRow = [
    {
      name: "Item Number",
      type: "asc",
      width: "w-[25%]",
    },
    {
      name: "Trip",
      type: "asc",
      width: "w-[15%]",
    },
    {
      name: "Travel Time",
      type: "asc",
      width: "w-[15%]",
    },
    {
      name: "Passenger",
      type: "asc",
      width: "w-[15%]",
    },
    {
      name: "Amount",
      type: "asc",
      width: "w-[15%]",
    },
    {
      name: "Status",
      type: "asc",
      width: "w-[20%]",
    },
  ];

  const exampleOrder = [
    {
      elements: [
        { type: "title", value: "20-07-2024 17:00:47 GMT+1" },
        { type: "copy", value: "40852853048358549475" },
        {
          type: "tag",
          value: "SABRE",
          color: "text-[#BD002A]",
          background: "bg-[#BD002A]/20",
        },
      ],
      width: "md:w-[25%]",
    },
    {
      elements: [
        { type: "title", value: "One Way" },
        { type: "text", value: "LOS - NBO KQ533" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Travel Time" },
        { type: "text", value: "2024-07-30 12:25 - 19:45" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Passenger" },
        { type: "text", value: "Chinedu John Doe" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Price" },
        { type: "text", value: "1310.9 USD" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Status" },
        { type: "status", value: "Awaiting payment" },
        { type: "time", value: "30 Mins" },
      ],
      width: "md:w-[15%]",
    },
  ];

  const getTicketFilterTypes = () => {
    return ticketFilterTypes;
  };

  const getTicketStatusFilterTypes = () => {
    return ticketStatusFilterTypes;
  };

  const getSortHeaderRow = () => {
    return ticketsSortHeader || ticketSortHeaderRow;
  };

  const selectSortHeader = (index) => {
    let items = [...getSortHeaderRow()];
    items = items.map((itm) => {
      return {
        name: itm?.name,
        type: itm?.type,
        width: itm?.width,
      };
    });
    let item = { ...items[index] };
    if (item?.name === selectedTicketsSortHeader?.name) {
      item.type = item.type === "asc" ? "desc" : "asc";
    }
    items[index] = item;
    dispatch(setTicketsSortHeader(items));
    dispatch(setSelectedTicketsSortHeader(item));
  };

  const getOrders = () => {
    return [
      exampleOrder,
      exampleOrder,
      exampleOrder,
      exampleOrder,
      exampleOrder,
    ];
  };

  return {
    getOrdersCount,
    selectOrderType,
    getTicketFilterTypes,
    getTicketStatusFilterTypes,
    getSortHeaderRow,
    selectSortHeader,
    getOrders,
  };
}

import React from "react";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementFlightVoidStepsHeader from "../../../components/TicketManagement/TicketManagementFlightVoidStepsHeader";
import { useDispatch } from "react-redux";
import { setConfirmFlightVoidRequestModal } from "../../../redux/reducers/modalSlice";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import ConfirmFlightVoidRequestModal from "../../../components/modal/ConfirmFlightVoidRequestModal";

function TicketManagementFlightVoid() {
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <ConfirmFlightVoidRequestModal />
      <TicketManagementLoadingModal />

      <TicketManagementHeader />
      <TicketManagementFlightVoidStepsHeader step={1} />
      <TicketManagementPassengerSelection />

      <TicketManagementContactDetails />
      <TicketActionButtons />
    </div>
  );
}

const TicketActionButtons = () => {
  const dispatch = useDispatch();
  const handleCancel = () => {};

  const handleSubmit = () => {
    dispatch(setConfirmFlightVoidRequestModal(true));
  };
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSubmit}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Submit refund request
      </button>
    </div>
  );
};

export default TicketManagementFlightVoid;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};

import { Pagination } from "@mui/material";
import React, { useState } from "react";

function OrderPagination() {
  const [page, setPage] = useState(1);

  const handlePageChange = (pg) => {
    setPage(pg);
  };

  return (
    <div className="flex justify-end mt-10">
      <Pagination
        count={10}
        onChange={(e, pg) => handlePageChange(pg)}
        page={page}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
}

export default OrderPagination;

import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'lodash';
import { crmData } from '../../../../data/customer/CRM';
import Icon from '../../../HOC/Icon';
import Modal1 from '../../../DIsplay/Modal/Modal1';
import Button1 from '../../../form/Button1';
import TextInput from '../../../form/TextInput';
import { FileUploader } from 'react-drag-drop-files';
import updateProfile from '../../../../controllers/user/updateProfile';
import { setUser } from '../../../../redux/reducers/userSlice';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import CreateFlightOrder from '../../../../pages/Orders/Flight/CreateFlightOrder';

const ModalContext = createContext(null);

var cancelTokenSource = null; 

export default function ContactCRM() {
    const {user} = useSelector(state => state.user.userData);
    let {contact} = clone(user?.detail?.crmData || crmData);
    // dbData.sections = dbData?.sections?.map(obj => ({...obj,image: undefined,savedImage: obj?.image}))
    const defData = clone({contact});
    const [data,setData] = useState((defData))
    const [modalOpen,setModalOpen] = useState({backgroundImage: false})
    const [loading,setLoading] = useState({submit: false})
    const dispatch = useDispatch();

    async function handleUpload(ev,newData) {
        ev?.preventDefault();
        // return console.log('payload: ',data)
        // if(bg?.file) {
        //     const formData = new FormData();
        //     formData.append('crmData.aboutUs.backgroundImage',bg?.file);
        //     const res = await updateProfile(formData)
        //     if(res.return) {
        //         enqueueSnackbar('Image Uploaded',{variant: 'success'})
        //     } else enqueueSnackbar(res.msg,{variant: 'error'})
        // }

        if(cancelTokenSource)
            cancelTokenSource.cancel('Canceled for another request')
        cancelTokenSource = axios.CancelToken.source()
        
        setLoading({...loading,submit: true});
        const res = await updateProfile({crmData: {...(user?.detail?.crmData || {}),...(newData || data || {})}},cancelTokenSource?.token)
        setLoading({...loading,submit: false});
        console.log(res)
        if(res.return) {
            enqueueSnackbar('Page Updated',{variant: 'success'})
            console.log(res.data)
            dispatch(setUser(res.data))
        } else enqueueSnackbar(res.msg,{variant: 'error'})

    }

    
    const EditModal = ({children,title,...props}) => {
        const [value,setValue] = useState('')

        function handleSubmit() {
            const updData = {...data,...value};
            setData(updData)
            handleUpload(null,updData)
            props?.setOpen && props?.setOpen(false);
        }
        
        return (
            <Modal1 {...props}>
                <div className='p-5 flex flex-col gap-4'>
                    <div className='flex gap-4 justify-between py-4'>
                        <b>{title}</b>
                        <Icon icon='mdi:close' onClick={() => props?.setOpen()} />
                    </div>
                    <ModalContext.Provider value={{value,setValue}}>
                        {children}
                    </ModalContext.Provider>
                    <div className='flex gap-4'>
                        <div>
                            <Button1 variant='text' onClick={() => props?.setOpen()}>Close</Button1>
                        </div>
                        <Button1 onClick={() => handleSubmit()}>Save</Button1>
                    </div>
                </div>
            </Modal1>
        )
    }

    console.log(data)

    function handleDeleteBackgroundImage(i) {
        try {
            const temp = {...data};
            temp.contact.backgroundImage = undefined;
            setData(temp);
        } catch(ex) {}
    }

    function handleRemoveCategory(i) {
        try {
            const temp = {...data};
            temp.contact.categories = temp?.contact?.categories?.filter((_,ind) => ind !== i)
            setData(temp);
        } catch(ex) {}
    }

    function handleRemoveWorkingDays(i) {
        try {
            const temp = {...data};
            temp.contact.workingDays = temp?.contact?.workingDays?.filter((_,ind) => ind !== i)
            setData(temp);
        } catch(ex) {}
    }

    const sections = [
        {title: 'Header Section',
            section: [
                {
                    label: 'Background Image',
                    body: <ImageListComponent handleDelete={(i) => handleDeleteBackgroundImage(i)} data={data?.contact?.backgroundImage ? [data?.contact?.backgroundImage] : null} />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,backgroundImage: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Change <Icon icon='tabler:edit' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.backgroundImage} setOpen={() => setModalOpen({...modalOpen,backgroundImage: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};

                                    async function handleBG(file) {
                                        // setBG({file,url: window.URL.createObjectURL(file)})
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(file);
                                        fileReader.onload = () => {
                                            const base64Data = fileReader.result.split(',')[1];
                                            setValue({contact: {...(data?.contact||{}),backgroundImage: base64Data}})
                                        }
                                    }
                                
                                    return (
                                        <div className=''>
                                            <FileUploader
                                                handleChange={handleBG} name={'files'} types={["JPG","JPEG","PNG","GIF"]}
                                            >
                                                <div className='border rounded-md p-4 flex gap-4 items-center text-blue-700'>
                                                    <div className='w-20 h-20 bg-primary/30 flex items-center justify-center rounded-md'>
                                                        {value?.contact?.backgroundImage ?
                                                            <img src={'data:image/jpeg;base64,'+value?.contact?.backgroundImage} className='w-full h-full object-cover' />
                                                        :<Icon icon='ph:image-thin' className='text-primary/50' />}
                                                    </div>
                                                    Upload Background Image <Icon icon='material-symbols:upload' />
                                                </div>
                                            </FileUploader>
                                            <p className='py-4'>
                                                *.png, *.jpg file at least 1920/1080px for best result
                                            </p>
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                }

            ]
        },
        {title: 'Form Section',
            section: [
                {
                    label: 'Question Category List',
                    body: <TextListComponent handleDelete={handleRemoveCategory} data={data?.contact?.categories} />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,cats: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Add <Icon icon='material-symbols:add' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.cats} setOpen={() => setModalOpen({...modalOpen,cats: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};

                                    return (
                                        <div className=''>
                                            <TextInput label={'Category Name'} onChange={(ev) => setValue({contact: {...data.contact,categories: [...(data?.contact?.categories || []),ev.target.value]}})} />
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                }
            ]
        },
        {title: 'Description Section',
            section: [
                {
                    label: 'Working Days',
                    body: <TextListComponent handleDelete={handleRemoveWorkingDays} data={data?.contact?.workingDays}
                        render={(obj) => (
                            <div>{obj?.day}</div>
                        )}
                     />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,workingHours: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Add <Icon icon='material-symbols:add' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.workingHours} setOpen={() => setModalOpen({...modalOpen,workingHours: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};

                                    return (
                                        <div className=' flex gap-4 flex-col'>
                                            <TextInput label={'Day'} onChange={(ev) => setValue({contact: {...data.contact,workingDays: [...(data?.contact?.workingDays || []),{day: ev.target.value,timeRange: value?.contact?.workingDays?.at(-1)?.timeRange || data?.contact?.workingDays?.at(-1)?.timeRange}]}})} />
                                            <div className='flex gap-4 items-center'>
                                                <TextInput label={'Time From'} onChange={(ev) => setValue({contact: {...data.contact,workingDays: [...(data?.contact?.workingDays || []),{day: value?.contact?.workingDays?.at(-1)?.day || data?.contact?.workingDays?.at(-1)?.day,timeRange: [ev.target.value,value?.contact?.workingDays?.timeRange?.at(1)||'']}]}})} />
                                                <TextInput label={'Time To'} onChange={(ev) => setValue({contact: {...data.contact,workingDays: [...(data?.contact?.workingDays || []),{day: value?.contact?.workingDays?.at(-1)?.day || data?.contact?.workingDays?.at(-1)?.day,timeRange: [value?.contact?.workingDays?.timeRange?.at(0)||'',ev.target.value]}]}})} />
                                            </div>
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                }
            ]
        }    ]

    let currentHost = window.location.protocol + "//"+ window.location.host;
    const iframeRefScale = useRef();
    const [scale,setScale] = useState(.3);

    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
          setScale((iframeRefScale.current.offsetWidth / screenWidth));
        }
    }
      useEffect(()=>{
        window.addEventListener('resize', updateScale)
        updateScale()
        return ()=>{
          window.removeEventListener('resize', updateScale)
        }
      },[])

    
  return (
    <div className='pd-md flex gap-4 flex-wrap md:flex-nowrap overflow-hidden'>
        <div className='md:w-[65%]'>
            <div className='pb-4'>
                <h3>Edit contact page content</h3>
            </div>
            <div className='flex flex-col gap-4'>
                {
                    sections?.map((section,i) => (
                        <div>
                            <div className='bg-theme1/10 p-4 py-2'>{section?.title}</div>
                            {section?.section?.map((obj,j) => (
                                <div key={section?.title+obj?.label+j} className='flex gap-4 p-4 border-b'>
                                    <div className='w-[150px]'>{obj?.label}</div>
                                    <div className='flex-1 max-w-[64%] overflow-hidden'>{obj?.body}</div>
                                    <div>{obj?.action(section)}</div>
                                </div>
                            ))}
                        </div>
                    ))
                }
            </div>
        </div>
        <div
            className="origin-top-left md:w-[30%]"
        >
            <iframe src={currentHost+'/order/new/flight/template/contact'} className='w-screen origin-top-left overflow-hidden min-h-[90vh]'
                style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
            />
        </div>
    </div>
  )
}

function getImageSource(val) {
    if((val||"")?.startsWith('http'))
        return val;
    else return "data:image/jpeg;base64,"+val
}

function ImageListComponent({data,handleDelete}) {

    return (
        (
            <div className='flex gap-4 max-w-full flex-wrap'>
                {data?.map((obj,i) => (
                    <div key={i}>
                        <div className='w-40 h-32 bg-primary/20 relative'>
                            <div className='absolute top-0 right-0 p-1 rounded-md m-2 bg-red-200 cursor-pointer'
                                onClick={() => handleDelete && handleDelete(i)}
                             >
                                <Icon icon='material-symbols:delete-outline' className='text-red-500 !w-5 !h-5' />
                            </div>
                            <img src={getImageSource(obj)} className='w-full h-full object-cover' />
                        </div>
                    </div>
                ))}
            </div>
        )
    )
}

function TextListComponent({data,render,handleDelete}) {

    return (
        (
            <div className='flex gap-4 max-w-full flex-wrap'>
                {data?.map((val,i) => (
                    <div key={i}>
                        <div className='px-3 py-1 bg-primary/20 rounded-full flex items-center capitalize gap-2 relative'>
                            {render ? render(val) : val}
                            <div className='rounded-md  cursor-pointer'
                                onClick={() => handleDelete && handleDelete(i)}
                             >
                                <Icon icon='mdi:close' className='!w-4 !h-4' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    )
}